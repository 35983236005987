import React, { useContext, useEffect } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { ScenarioContext } from '../../context/scenarioContext'
import { getFontUrl } from '../../helpers/fonts'
import { LoadContext } from '../../context/loadContext'
import { SceneContext } from '../../context/sceneContext'

const StartScreen = () => {
  const { t } = useTranslation()
  const { scenarioDetail } = useContext(ScenarioContext)
  const { dispatch } = useContext(LoadContext)
  const { dispatchAttempt } = useContext(SceneContext)

  const handleFontLoading = () => dispatch({ type: 'UPDATE_LOADING_STATE', value: { font: false } })

  useEffect(() => {
    const startButtonEl = document.querySelector('#startbutton-text-button')
    startButtonEl.addEventListener('textfontset', handleFontLoading, true)
    return () => {
      startButtonEl.removeEventListener('textfontset', handleFontLoading, true)
    }
  }, [])

  const handleStart = () => {
    document.querySelector('#video').play()
    dispatchAttempt({ type: 'START', uuid: uuidV4() })
  }

  return (
    <a-entity
      hide-once-playing
      id="message"
      material="color:black;"
      geometry="primitive:plane; width:20; height:10;"
      position="0 0 -6"
    >
      <a-circle color="#FFF" scale="0.3 0.3 0.3" position="0 1.33 0.04" rotation="0 0 0">
        <a-circle
          scale="0.85 0.85 0.85"
          position="0 0 0.01"
          src="#client-mobile-logo"
          rotation="0 0 0"
        />
      </a-circle>

      <a-entity position="0 0.8 0.01" scale="1 1 1">
        <a-text align="center" color="gray" value={scenarioDetail.client.appTitle} width="3.6" />
      </a-entity>
      <a-entity position="0 0.4 0.01">
        <a-text align="center" value={scenarioDetail.name} />
      </a-entity>
      <a-entity position=" 0 -0.0.5 0.1">
        <a-entity scale="1 1 1" id="start-button" button="color: white; paddingY: 0.1">
          <a-text
            id="startbutton-text-button"
            font={getFontUrl('LATIN')}
            negate="false"
            shader="msdf"
            value={t('start_scenario', 'Start scenario')}
            color="black"
            anchor="center"
            align="center"
            material="shader:flat;visible: false;"
            geometry="primitive:plane;width:auto;height:auto"
            position="0 0.018 0"
            wrap-count="15"
            width="1.6"
            letter-spacing={-2}
          />
          <a-entity id="button" data-button onClick={handleStart} />
        </a-entity>
      </a-entity>
      <a-entity position="0 -1.2 0.01">
        <a-entity scale="0.6 0.6 1" position="-0.6 0 0">
          <a-image src="#icon-click-drag" />
          <a-text
            position="0 -1 0"
            align="center"
            color="gray"
            value={t('click_drag_look', 'Click and drag \n to look around')}
          />
        </a-entity>
        <a-entity scale="0.6 0.6 0.6" position="0.6 0 0">
          <a-image src="#icon-click-select" />
          <a-text
            position="0 -1 0"
            align="center"
            color="gray"
            value={t('click_select', 'Click to select')}
          />
        </a-entity>
        <a-entity scale="0.6 0.6 0.6" position="0 -0.5 0">
          <a-text
            position="0 -1 0"
            align="center"
            color="gray"
            value={t('scroll_zoom', 'Scroll to zoom')}
          />
        </a-entity>
      </a-entity>
    </a-entity>
  )
}

export default StartScreen
