import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { gql, useLazyQuery } from '@apollo/client'
import { FaArrowLeft } from 'react-icons/fa'
import ScenarioModal from '../components/Scenario/ScenarioModal'
import AboutOrganizationModal from '../components/Client/AboutOrganizationModal'
import ScenarioCard from '../components/Scenario/ScenarioCard'

export const GET_COURSE_DETAIL = gql`
  query getCourses($id: ID) {
    courses(id: $id) {
      id
      name
      description
      buyingClient {
        id
        name
        appTitle
        appDescription
      }
      client {
        id
        appTitle
        appDescription
        logoMobileUrl
      }
      scenarios {
        id
        name
        imageUrl
        scoringSystem {
          kind
        }
        highscore
      }
    }
  }
`

// Get image url from env
const imageUrl = process.env.REACT_APP_IMAGE_URL

const Course = () => {
  const { t } = useTranslation()
  const { courseId, scenarioId } = useParams()
  const [course, setCourse] = useState()
  const [selectedScenario, setSelectedScenario] = useState()
  const [showModal, setShowModal] = useState(false)
  const [showOrgModal, setShowOrgModal] = useState(false)
  const navigate = useNavigate()

  const [getCourseDetail] = useLazyQuery(GET_COURSE_DETAIL, {
    onError: (error) => {
      throw new Error(error.message)
    },
    onCompleted: (data) => {
      const courseData = { ...data.courses[0] }
      courseData.scenarios = courseData.scenarios.map((scenario) => ({
        id: scenario.id,
        name: scenario.name,
        highscore: scenario.highscore,
        scoringKind: scenario.scoringSystem.kind,
        description: scenario.description,
        testing: scenario.testing,
        rating: null,
        image: `${imageUrl}/${scenario.imageUrl}`,
        logo: `${imageUrl}/${courseData.client.logoMobileUrl}`,
      }))

      setCourse(courseData)
    },
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    getCourseDetail({
      variables: {
        id: courseId,
      },
    })
  }, [courseId])

  useEffect(() => {
    if (scenarioId && course) {
      const scenario = course.scenarios.find((s) => s.id === scenarioId)
      setSelectedScenario(scenario)
      setShowModal(true)
    } else {
      setSelectedScenario(null)
    }
  }, [scenarioId, course])

  if (!course) return t('loading', 'Loading')

  const openModalHandler = (scId) => {
    navigate(`/courses/${courseId}/${scId}/modal`)
  }

  const closeModalHandler = () => {
    navigate(`/courses/${courseId}`)
  }

  const renderBuyingClient = () => {
    if (!course.buyingClient || course.buyingClient.id === course.client.id) return null
    return (
      <div className="my-9">
        <span className="rounded-3xl bg-black px-6 py-3 font-semibold text-white">
          {t('provided_by', 'Provided by').replace('{0}', course.buyingClient.appTitle)}
        </span>
      </div>
    )
  }

  return (
    <>
      <div className="inline-block">
        <Link
          to="/"
          className="flex items-center font-semibold text-warp-gray hover:bg-gray-200 hover:text-gray-600 py-2 px-6 duration-300 rounded-[40px]"
        >
          <FaArrowLeft size={18} className="mr-2" />
          {t('back_to_menu', 'Back to menu')}
        </Link>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 room:grid-cols-4 gap-8 pt-9">
        <div className="col-span-12 xl:col-span-1 lg:col-span-1">
          {renderBuyingClient()}
          <button
            type="button"
            className="mb-9 flex items-center"
            onClick={() => setShowOrgModal(true)}
          >
            <img
              className="h-14 w-14 room:h-[26px] room:w-[26px] shrink-0 self-end rounded-50 bg-white p-2 border-hex-ddd border"
              src={`${imageUrl}${course.client.logoMobileUrl}`}
              alt="logo client"
              loading="lazy"
            />
            <span className="ml-4 font-semibold text-warp-gray room:text-xs room:font-normal">
              {course.client.appTitle}
            </span>
          </button>
          <article>
            <h1 className="text-[1.625rem] room:text-base font-bold">{course.name}</h1>
            <h2 className="mt-6 font-semibold room:text-xs">
              {t('about_course', 'About this course')}
            </h2>
            <p className="mt-3 text-warp-gray room:text-xs">{course.description}</p>
          </article>
        </div>
        <div className="relative col-span-12 grid w-full cursor-pointer gap-8 room:gap-4 xl:col-span-2 room:col-span-3 lg:col-span-1 grid-cols-1 xl:grid-cols-2 3xl:grid-cols-3 room:grid-cols-3">
          {course.scenarios.map((scenario) => (
            <ScenarioCard
              key={scenario.id}
              scenario={scenario}
              onClick={() => openModalHandler(scenario.id)}
            />
          ))}
        </div>
      </div>

      {selectedScenario && (
        <ScenarioModal
          showModal={showModal}
          scenario={{ ...selectedScenario, course }}
          onClose={closeModalHandler}
        />
      )}

      <AboutOrganizationModal
        showModal={showOrgModal}
        client={course.client}
        onClose={() => setShowOrgModal(false)}
      />
    </>
  )
}
export default Course
