import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FaSearch } from 'react-icons/fa'
import Modal from '../Modal'
import { SceneContext } from '../../context/sceneContext'
import { ScenarioContext } from '../../context/scenarioContext'

const JumpToSceneModal = ({ show, onClose, onJump }) => {
  const { t } = useTranslation()
  const { scenarioDetail } = useContext(ScenarioContext)
  const { scene: currentScene } = useContext(SceneContext)
  const [term, setTerm] = useState('')
  const scenes = scenarioDetail.scenes.filter((scene) =>
    !term
      ? true
      : scene.name.toLowerCase().includes(term.toLowerCase()) || scene.number.toString() === term
  )

  return (
    <Modal
      show={show}
      maxWidth="3xl"
      onClose={onClose}
      headerText={t('jump_scene', 'Jump to scene')}
      closeButton
      footerText=" "
    >
      <div className="pb-14">
        <div className="relative mb-3">
          <input
            type="text"
            className="h-[52px] w-full pl-14"
            placeholder={`${t('scene_search_placeholder', 'Search by scene number or title')}...`}
            onChange={({ target }) => setTerm(target.value)}
          />
          <FaSearch className="absolute left-5 top-[18px] text-[#1b1c22]" />
        </div>
        {scenes
          .sort((a, b) => a.number - b.number)
          .map((scene) => (
            <button
              key={scene.id}
              type="button"
              className={`flex h-[52px] w-full items-center justify-start pl-5 hover:bg-[#f8f8f8] ${
                scene.id === currentScene.id ? 'bg-[#f8f8f8]' : ''
              }`}
              onClick={() => onJump(scene.id)}
            >
              {scene.number}. {scene.name}
            </button>
          ))}
      </div>
    </Modal>
  )
}

JumpToSceneModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onJump: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

JumpToSceneModal.defaultProps = {
  onClose: () => {},
}

export default JumpToSceneModal
