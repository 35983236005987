import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { ScenarioContext } from '../../context/scenarioContext'
import { SceneContext } from '../../context/sceneContext'

const ScoreModal = ({ show, onClose }) => {
  const { t } = useTranslation()
  const {
    attemptState: { answers: chosenAnswers },
  } = useContext(SceneContext)
  const { scenarioDetail } = useContext(ScenarioContext)
  const { kind: scoringKind } = scenarioDetail.scoringSystem
  let sum = 0

  return (
    <Modal show={show} maxWidth="3xl" onClose={onClose} closeButton>
      <div className="pt-14 pb-14">
        <h1 className="mb-2 text-[26px] font-bold">{t('current_score', 'Current score')}</h1>
        <h2 className="mb-1 text-warp-gray">
          {scoringKind === 'SUBTRACT'
            ? t('subtracting_kind', 'Subtracting points')
            : t('adding_kind', 'Adding points')}
        </h2>

        <table className="mt-7 w-full">
          <thead className="text-left">
            <tr className="my-4">
              <th className="py-4">{t('scene', 'Scene')}</th>
              <th className="py-4">{t('answer', 'Answer')}</th>
              <th className="py-4 text-right">{t('score', 'Score')}</th>
            </tr>
          </thead>
          <tbody>
            {chosenAnswers.map((answerId) => {
              const scene = scenarioDetail.scenes.find((s) =>
                s.elements.find((e) => e.id === answerId)
              )
              const choosenAnswer = scene.elements.find((e) => e.id === answerId)
              sum += choosenAnswer.points

              return (
                <tr key={answerId}>
                  <td className="py-4">
                    {scene.number}. {scene.name}
                  </td>
                  <td className="py-4">
                    {String.fromCharCode(64 + choosenAnswer.number)}. {choosenAnswer.name}
                  </td>
                  <td className="py-4 text-right">{choosenAnswer.points}</td>
                </tr>
              )
            })}
            <tr>
              <td className="pt-4 text-left font-bold" colSpan={2}>
                {t('total_points', 'Total points')}
              </td>
              <td className="pt-4 text-right font-bold">{sum}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

ScoreModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

ScoreModal.defaultProps = {
  onClose: () => {},
}

export default ScoreModal
