import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'

import { ScenarioContext } from '../../../../context/scenarioContext'
import { SceneContext } from '../../../../context/sceneContext'

const ActiveSceneInformation = ({
  showSceneOrbs,
  setShowSceneOrbs,
  showNorthOffsetLine,
  setShowNorthOffsetLine,
  isImmersiveRoom,
  setIsImmersiveRoom,
}) => {
  const sceneNumberInputRef = useRef()
  const { scenarioDetail } = useContext(ScenarioContext)
  const { scene, currentSceneSettings, setCurrentSceneSettings, handleSceneChange } =
    useContext(SceneContext)

  const jumpToScene = () => {
    const sc = scenarioDetail.scenes.find(
      (s) => s.number === Number(sceneNumberInputRef.current.value)
    )
    if (sc.id) handleSceneChange(sc.id)
    sceneNumberInputRef.current.value = ''
  }
  const getActiveSceneInformation = () => {
    const entries = Object.entries({ ...scene, ...currentSceneSettings })

    const listItems = entries.reduce((array, [key, value]) => {
      // filter out unwanted information:
      if (['__typename', 'description', 'question'].includes(key)) return array
      // filter false values
      if (value === false) return array
      // dont loop the objects:
      if (typeof value === 'object') return array
      if (['anchorX', 'anchorY'].includes(key))
        array.push(
          <li key={`item-${key}`} className="flex">
            <span className="mr-10 flex-grow">{key}</span>
            <span className="text-right">{value.toFixed(3)} </span>
          </li>
        )
      else {
        array.push(
          <li key={`item-${key}`} className="flex">
            <span className="mr-10 flex-grow">{key}</span>
            <span className="text-right">{value.toString()} </span>
          </li>
        )
      }
      return array
    }, [])
    return <ul>{listItems}</ul>
  }

  return (
    <div className="absolute z-10 m-3 h-auto w-96 text-xs">
      <div className="mb-3 rounded border-2  bg-white bg-opacity-50 px-4 py-2">
        <p className="mb-1 font-bold">Scenario Info</p>
        <li className="flex">
          <span className="mr-10 flex-grow">client</span>
          <span className="text-right">{scenarioDetail.client.name} </span>
        </li>
        <li className="mb-3 flex">
          <span className="mr-10 flex-grow">scenario</span>
          <span className="text-right">{scenarioDetail.name} </span>
        </li>
        <p className="mb-3 font-bold">Scene Info</p>
        {getActiveSceneInformation()}
      </div>
      <div className="rounded border-2  bg-white bg-opacity-50 px-3 py-2 text-xs">
        <p className="mb-3 font-bold">Commands</p>
        <div className="mb-3 flex flex-wrap">
          <button
            type="button"
            className="mb-1 mr-1 rounded bg-slate-300 px-3 py-2 transition-all button hover:bg-slate-500 hover:shadow-md"
            onClick={() => setShowSceneOrbs(!showSceneOrbs)}
          >
            {showSceneOrbs ? 'Hide ' : 'Show '} orbs
          </button>
          <button
            type="button"
            className="mb-1 mr-1 rounded bg-slate-300 px-3 py-2 transition-all button hover:bg-slate-500 hover:shadow-md"
            onClick={() =>
              setCurrentSceneSettings({
                ...currentSceneSettings,
                showQuestion: !currentSceneSettings.showQuestion,
              })
            }
          >
            toggle elements
          </button>

          <button
            type="button"
            className="mb-1 mr-1 rounded bg-slate-300 px-3 py-2 transition-all button hover:bg-slate-500 hover:shadow-md"
            onClick={() => setShowNorthOffsetLine(!showNorthOffsetLine)}
          >
            {showNorthOffsetLine ? 'Hide ' : 'Show '} north offset line
          </button>

          <button
            type="button"
            className="mb-1 mr-1 rounded bg-slate-300 px-3 py-2 transition-all button hover:bg-slate-500 hover:shadow-md"
            onClick={() => setIsImmersiveRoom(!isImmersiveRoom)}
          >
            {isImmersiveRoom ? 'Disable ' : 'Enable '} immersive room
          </button>

          <button
            type="button"
            className="rounded bg-slate-300 px-3 py-2 transition-all button hover:bg-slate-500 hover:shadow-md"
            onClick={() => handleSceneChange(scenarioDetail.startSceneId)}
          >
            Restart
          </button>
        </div>
        <div className="flex justify-between space-x-3">
          <input
            ref={sceneNumberInputRef}
            className="flex-grow rounded px-2"
            type="text"
            name="number"
            id="number"
            onSubmit={jumpToScene}
            placeholder="Jump to scene no&deg;"
            autoComplete="off"
          />
          <button
            type="button"
            className="rounded bg-slate-300 px-4 py-2 transition-all button hover:bg-slate-500 hover:shadow-md"
            onClick={jumpToScene}
          >
            Go
          </button>
        </div>
      </div>
    </div>
  )
}

export default ActiveSceneInformation

ActiveSceneInformation.propTypes = {
  setShowSceneOrbs: PropTypes.func.isRequired,
  showSceneOrbs: PropTypes.bool.isRequired,
  showNorthOffsetLine: PropTypes.bool.isRequired,
  setShowNorthOffsetLine: PropTypes.func.isRequired,
  isImmersiveRoom: PropTypes.bool.isRequired,
  setIsImmersiveRoom: PropTypes.func.isRequired,
}
