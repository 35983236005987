import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaHome, FaSearch } from 'react-icons/fa'
import PropTypes from 'prop-types'
import HudButton from './HudButton'
import { ELEMENTS_STORAGE_KEY, SceneContext } from '../../context/sceneContext'
import { ScenarioContext } from '../../context/scenarioContext'
import SceneInfoModal from './SceneInfoModal'
import calculateScenarioScore from '../../helpers/calculateScenarioScore'
import JumpToSceneModal from './JumpToSceneModal'
import ScoreModal from './ScoreModal'

const TOOLTIP_POSITION = 'bottom'

const TestingControls = ({ isShowingQuestionFadeIn }) => {
  const { t } = useTranslation()
  const [elements, setElements] = useState(localStorage.getItem(ELEMENTS_STORAGE_KEY))
  const [showControls, setShowControls] = useState()
  const {
    handleSceneChange,
    scene,
    attemptState: { answers: chosenAnswers },
    previousScene,
    sceneSeries,
    currentSceneSettings,
    setCurrentSceneSettings,
  } = useContext(SceneContext)
  const { scenarioDetail } = useContext(ScenarioContext)
  const [showSceneInfoModal, setShowSceneInfoModal] = useState(false)
  const [showJumpSceneModal, setShowJumpSceneModal] = useState(false)
  const [showScoreModal, setShowScoreModal] = useState(false)

  const score = calculateScenarioScore(scenarioDetail, chosenAnswers)

  const toggleControls = () => setShowControls(!showControls)

  const restartScenario = () => window.location.reload()

  const handleElement = () => {
    const value = elements === 'always_on' ? 'normal' : 'always_on'
    setElements(value)
    localStorage.setItem(ELEMENTS_STORAGE_KEY, value)
    setCurrentSceneSettings({
      ...currentSceneSettings,
      showQuestion: value === 'always_on' || isShowingQuestionFadeIn(),
    })
  }
  const handleJump = (sceneId) => {
    handleSceneChange(sceneId)
    setShowJumpSceneModal(false)
  }

  const handlePreviousScene = () => {
    previousScene()
  }

  return (
    <>
      <div className="absolute top-10 right-10 z-10 flex text-white">
        <button
          type="button"
          className={`flex h-12 flex-shrink-0 items-center justify-center rounded-3xl px-5 text-sm font-bold opacity-70 hover:cursor-pointer transition-all duration-500 ${
            showControls
              ? 'mr-6 bg-warp-yellow text-black !opacity-100'
              : 'bg-black text-warp-yellow'
          }`}
          onClick={toggleControls}
        >
          {t('testing_controls', 'Testing controls')}
        </button>

        <div
          className={`transition-all duration-500 flex items-center space-x-6 flex-shrink ${
            showControls ? 'max-w-[1000px]' : 'max-w-0 overflow-hidden'
          }`}
        >
          <HudButton
            title={t('restart_scenario', 'Restart scenario')}
            onClick={restartScenario}
            tooltipPosition={TOOLTIP_POSITION}
          >
            <FaHome className="text-xl" />
          </HudButton>

          <HudButton
            className={sceneSeries.length === 1 ? 'pointer-events-none !opacity-30' : ''}
            title={t('previous_scene', 'Previous scene')}
            onClick={handlePreviousScene}
            tooltipPosition={TOOLTIP_POSITION}
          >
            <FaArrowLeft className="text-xl" />
          </HudButton>

          <HudButton
            title={t('jump_scene', 'Jump to scene')}
            onClick={() => setShowJumpSceneModal(true)}
            tooltipPosition={TOOLTIP_POSITION}
          >
            <FaSearch className="text-xl" />
          </HudButton>

          <HudButton
            className={`w-auto !rounded-3xl px-6 text-sm ${
              elements === 'always_on' ? 'bg-white text-black !opacity-100' : ''
            }`}
            onClick={handleElement}
            isCircle={false}
          >
            <span className="inline-block">
              <span className="font-bold">{t('elements', 'Elements')}</span>
              <span className="ml-9">
                {elements === 'always_on' ? t('always_on', 'Always on') : t('normal', 'Normal')}
              </span>
            </span>
          </HudButton>

          <HudButton
            className="w-auto !rounded-3xl px-6 text-sm"
            onClick={() => setShowSceneInfoModal(true)}
            isCircle={false}
          >
            <span>
              <span className="font-bold">{t('scene', 'Scene')}</span>
              <span className="ml-2">{scene.number}</span>
            </span>
          </HudButton>

          {scenarioDetail.scoringSystem.scoringKind !== 'DISABLED' && (
            <HudButton
              className="w-auto !rounded-3xl px-6 text-sm"
              onClick={() => setShowScoreModal(true)}
              isCircle={false}
            >
              <span>
                <span className="font-bold">{t('score', 'Score')}</span>
                <span className="ml-2">
                  {score > 0 ? '+' : ''}
                  {score}
                </span>
              </span>
            </HudButton>
          )}
        </div>
      </div>

      <SceneInfoModal show={showSceneInfoModal} onClose={() => setShowSceneInfoModal(false)} />
      <JumpToSceneModal
        show={showJumpSceneModal}
        onClose={() => setShowJumpSceneModal(false)}
        onJump={handleJump}
      />
      <ScoreModal show={showScoreModal} onClose={() => setShowScoreModal(false)} />
    </>
  )
}

export default TestingControls

TestingControls.propTypes = {
  isShowingQuestionFadeIn: PropTypes.func.isRequired,
}
