import React from 'react'
import PropTypes from 'prop-types'

const ClientLogo = ({ src, className }) => (
  <img
    className={`h-14 w-14 shrink-0 self-end rounded-50 bg-white p-2 shadow-lg ${className}`}
    src={src}
    alt="logo client"
    loading="lazy"
  />
)
export default ClientLogo

ClientLogo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
}
ClientLogo.defaultProps = {
  className: '',
}
