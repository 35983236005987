import 'aframe'
import '../components/aframe/scene-settings'
import '../components/aframe/custom-loading-handler'
import '../components/aframe/hide-once-playing'
import '../components/aframe/objects/partials/aframe-camera-controls'
import '../components/aframe/objects/partials/aframe-button'
import '../components/aframe/objects/partials/aframe-rounded'
import '../components/aframe/objects/partials/aframe-text-bubble'
import '../components/aframe/objects/partials/aframe-mpc'
import '../components/aframe/objects/partials/aframe-hideable-element'
import '../components/aframe/multi-camera'
import '../components/aframe/cursor'

import React, { useContext, useEffect, useRef, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ScenarioContext } from '../context/scenarioContext'
import SceneOrbs from '../components/aframe/DevTools/SceneOrbs'

// assets
import sky from '../assets/images/skyclouds.jpg'
import clickDrag from '../assets/images/clickdrag.svg'
import clickSelect from '../assets/images/clickselect.svg'
import arrow from '../assets/images/direction-arrow.svg'
import blackVideo from '../assets/videos/blackVideo.mp4'

import Camera from '../components/Player/Camera'
import { SceneContext } from '../context/sceneContext'
import Elements from '../components/Player/Elements'
import LoadingPage from './LoadingPage'
import { LoadContext } from '../context/loadContext'
import Nadir from '../components/aframe/objects/Nadir'
import RoomHUD from '../components/Player/RoomHUD'
import UserHUD from '../components/Player/UserHUD'
import EndingPage from './EndingPage'
import NorthOffsetLine from '../components/aframe/DevTools/NorthOffsetLine'
import useBackButtonBlocker from '../helpers/hooks/useBackButtonBlocker'
import useRefreshBlocker from '../helpers/hooks/useRefreshBlocker'
import LowSpeedBanner from '../components/Player/LowSpeedBanner'
import { moveCameraToPosition } from '../components/aframe/helpers/controls'
import useScenarioLicenseCheck from '../helpers/hooks/useScenarioLicenseCheck'

const VIDEO_LOOP_THRESHOLD = 2 // seconds
let myTimer

const Player = () => {
  const { t } = useTranslation()
  const { scenarioId } = useParams()
  const videoRef = useRef()
  const [showDevTools, setShowDevTools] = useState(false)
  const [showSceneOrbs, setShowSceneOrbs] = useState(false)
  const [showNorthOffsetLine, setShowNorthOffsetLine] = useState(true)
  const { loading, dispatch } = useContext(LoadContext)
  const { scenarioDetail, getScenarioDetail } = useContext(ScenarioContext)
  const { licenseResult, licenseStatus } = useScenarioLicenseCheck({
    scenarioId,
  })
  const [videoCanPlay, setVideoCanPlay] = useState(false)
  const navigate = useNavigate()
  const timerProgressRef = useRef()
  const [videoUrl, setVideoUrl] = useState('')

  const {
    scene,
    isEnding,
    setIsEnding,
    setIsAborted,
    dispatchAttempt,
    currentSceneSettings,
    setCurrentSceneSettings,
    handleSceneChange,
    videoLoaded,
    setVideoLoaded,
    hasStarted,
    isImmersiveRoom,
    handleEnding,
  } = useContext(SceneContext)
  const [selectedQuality, setSelectedQuality] = useState('4kCdn')

  const handleKeys = (e) => {
    if (e.key === 'F3') {
      e.preventDefault()
      setShowDevTools((state) => !state)
    } else if (e.key === 'F4') {
      e.preventDefault()
      if (hasStarted) dispatchAttempt({ type: 'ABORT', uuid: uuidV4() })
      setIsAborted(true)
      setIsEnding(true)
    }
  }

  const handleLoading = (e) => {
    switch (e.detail.name) {
      case 'videosphere':
        return dispatch({ type: 'UPDATE_LOADING_STATE', value: { video: false } })
      case 'camera':
      case 'aframe':
      case 'font':
        return dispatch({ type: 'UPDATE_LOADING_STATE', value: { [e.detail.name]: false } })
      default:
        return null
    }
  }

  const backToScenariosList = () => {
    const url = new URL(window.location.hash.replace('#', window.location.origin))
    const urlParams = new URLSearchParams(url.search)
    const returnUrl = urlParams.get('returnUrl') ?? '/'
    navigate(returnUrl)
  }

  const moveCameraToNorthOffsetPosition = () => {
    if (!loading.isLoading.camera)
      if (scene?.northOffsetEnabled && videoLoaded) {
        moveCameraToPosition(Number(0), -Number(scene.northOffset))
      }
  }

  const startTimer = (timerEl, sceneStartedAt, timerTimeInSeconds) => {
    const currentTime = Date.now()
    const timerEndsAt = sceneStartedAt + timerTimeInSeconds * 1000
    if (currentTime >= timerEndsAt) {
      clearInterval(myTimer)
      myTimer = null
      dispatchAttempt({
        type: 'TIMER',
        value: { sceneId: scene.id, answerId: timerEl.id },
        uuid: uuidV4(),
      })

      if (timerEl.linkToEnding) handleEnding()
      else handleSceneChange(timerEl.linkToId)
    }
    timerProgressRef.current.style.width = `${(
      (-(currentTime - timerEndsAt) / (timerTimeInSeconds * 1000)) *
      100
    ).toFixed(1)}%`
  }

  useEffect(() => {
    dispatch({ type: 'RESET_LOADING_STATE' })
    document.addEventListener('custom-loading', handleLoading, true)
    document.addEventListener('keydown', handleKeys, true)
    return () => {
      document.removeEventListener('keydown', handleKeys, true)
      document.documentElement.classList.remove('a-fullscreen')
    }
  }, [])

  useEffect(() => {
    dispatch({ type: 'RESET_LOADING_STATE' })
    getScenarioDetail({
      variables: {
        id: scenarioId,
      },
    })
    dispatch({ type: 'UPDATE_LOADING_STATE', value: { scenario: false } })
  }, [scenarioId])

  useEffect(moveCameraToNorthOffsetPosition, [scene, loading.isLoading.camera, videoLoaded])

  useEffect(() => {
    clearInterval(myTimer)
    if (isEnding)
      setCurrentSceneSettings({
        ...currentSceneSettings,
        showTimer: false,
      })
    else if (hasStarted && videoCanPlay) {
      videoRef.current.play()
      const timerEl = scene.elements.find((el) => el.kind === 'TIMER')
      if (timerEl) {
        const timerTimeInSeconds = timerEl.timerMinutes * 60 + timerEl.timerSeconds
        const sceneStartedAt = Date.now()
        setCurrentSceneSettings({
          ...currentSceneSettings,
          // showTimer: scene.timer.visible,
          showTimer: true,
        })
        myTimer = setInterval(
          () => startTimer(timerEl, sceneStartedAt, timerTimeInSeconds),
          1000 / 60
        )
      }
    }

    return () => clearInterval(myTimer)
  }, [videoCanPlay, hasStarted, scene, isEnding])

  useEffect(() => setVideoCanPlay(false), [videoUrl])

  useBackButtonBlocker(hasStarted && !isEnding && !scenarioDetail.testing, backToScenariosList)
  useRefreshBlocker(hasStarted && !isEnding && !scenarioDetail.testing)

  const getVideoUrl = () => {
    let url = scene?.video?.[`play${selectedQuality}Url`] ?? scene?.video?.play4kUrl
    if (url) {
      if (url.startsWith('/')) {
        url = process.env.REACT_APP_IMAGE_URL + url
      }
    } else {
      url = blackVideo
    }

    return url
  }

  useEffect(() => {
    setVideoUrl(getVideoUrl())
  }, [selectedQuality, scene])

  if (loading.isLoadingData || !licenseStatus) return <LoadingPage />

  if (licenseStatus === 'MISSING' && licenseResult === 'FAILED')
    return (
      <div className="fixed flex h-full w-full items-center justify-center">
        <p className="max-w-md rounded bg-warp-yellow px-7 py-5 text-left text-black">
          {t(
            'scenario_license_error_msg',
            'Unfortunately at this moment there aren’t enough licenses available to play this course. Contact your training manager for assistance.'
          )}
        </p>
      </div>
    )

  const onEnded = async () => {
    const transitionEl = scene.elements.find((el) => el.kind === 'TRANSITION')
    if (transitionEl) {
      await dispatchAttempt({
        type: 'CHOICE',
        value: { sceneId: scene.id, answerId: transitionEl.id },
        uuid: uuidV4(),
      })
      if (transitionEl.linkToEnding) return handleEnding()
      return handleSceneChange(transitionEl.linkToId)
    }
    if (
      scene.videoLoopEnabled &&
      scene.videoLoop + VIDEO_LOOP_THRESHOLD <= videoRef.current.duration
    ) {
      videoRef.current.currentTime = scene.videoLoop
      videoRef.current.play()
    }
    return true
  }

  const isShowingQuestionFadeIn = () => videoRef.current.currentTime >= scene.elementsFadeIn

  const onTimeUpdate = () => {
    if (!hasStarted) return false
    // HOTSPOTS have an additional timer when hidden completely:
    if (scene.elements.some((el) => el.kind === 'HOTSPOT') && scene.hotspotHintEnabled === true) {
      if (
        !currentSceneSettings.showHint &&
        videoRef.current.currentTime >= scene.hotspotHintAfter + scene.elementsFadeIn
      ) {
        setCurrentSceneSettings({ ...currentSceneSettings, showHint: true })
      }
    }
    if (!currentSceneSettings.showQuestion && isShowingQuestionFadeIn()) {
      setCurrentSceneSettings({ ...currentSceneSettings, showQuestion: true })
    }
    return true
  }

  const onCanPlay = () => setVideoCanPlay(true)

  const onVideoLoaded = () => {
    if (!videoLoaded) {
      setTimeout(() => {
        moveCameraToNorthOffsetPosition()
        setVideoLoaded(true)
      }, 50)
    }
  }

  const onChange = () => setVideoLoaded(false)

  const getDevTools = () => (
    <>
      {showSceneOrbs && <SceneOrbs />}
      {showNorthOffsetLine && videoLoaded && <NorthOffsetLine northOffset={scene.northOffset} />}
    </>
  )

  const getImmersiveRoomCameras = () => (
    <>
      <a-entity
        id="iglooleftcamera"
        cursor="rayOrigin: mouse; fuse: false; camera: user; canvas: user"
        raycaster="objects:[data-button]; near: 1; far: 8; interval: 100"
        secondary-camera="fov: 90; outputElement: #iglooleftdiv; sequence: replace"
        position="0 0 0"
        rotation="0 90 0"
      />
      <a-entity
        id="igloofrontcamera"
        cursor="rayOrigin: mouse; fuse: false; camera: user; canvas: user"
        raycaster="objects: [data-button]; near: 1; far: 8; interval:100"
        secondary-camera="fov: 90; outputElement: #igloofrontdiv"
        position="0 0 0"
        rotation="0 0 0"
      />
      <a-entity
        id="igloorightcamera"
        cursor="rayOrigin: mouse; fuse: false; camera: user; canvas: user"
        raycaster="objects: [data-button]; near: 1; far: 8; interval: 100"
        secondary-camera="fov: 90; outputElement: #igloorightdiv"
        position="0 0 0"
        rotation="0 -90 0"
      />
      <a-entity
        id="igloobackcamera"
        cursor="rayOrigin: mouse; fuse: false; camera: user; canvas: user"
        raycaster="objects: [data-button]; near: 1; far: 8; interval:100"
        secondary-camera="fov: 90; outputElement: #igloobackdiv"
        position="0 0 0"
        rotation="0 180 0"
      />
      <a-entity
        id="igloobottomcamera"
        cursor="rayOrigin: mouse; fuse: false; camera: user; canvas: user"
        raycaster="objects:[data-button];near:1; far:8; interval:100"
        secondary-camera="fov:90;outputElement:#igloobottomdiv"
        position="0 0 0"
        rotation="-90 0 0"
      />
      <a-entity
        id="iglootopcamera"
        cursor="rayOrigin: mouse; fuse: false; camera: user; canvas: user"
        raycaster="objects: [data-button]; near: 1; far: 8; interval: 100"
        secondary-camera="fov:90; outputElement: #iglootopdiv"
        position="0 0 0"
        rotation="90 0 0"
      />
    </>
  )

  const getImmersiveRoomContainer = () => (
    <div
      id="igloodiv"
      style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%' }}
    >
      <div
        id="iglooleftdiv"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '16.67%',
          height: '100%',
        }}
      />
      <div
        id="igloofrontdiv"
        style={{
          position: 'absolute',
          left: '16.67%',
          top: 0,
          width: '16.67%',
          height: '100%',
        }}
      />
      <div
        id="igloorightdiv"
        style={{
          position: 'absolute',
          left: '33.33%',
          top: 0,
          width: '16.67%',
          height: '100%',
        }}
      />
      <div
        id="igloobackdiv"
        style={{
          position: 'absolute',
          left: '50%',
          top: 0,
          width: '16.667%',
          height: '100%',
        }}
      />
      <div
        id="igloobottomdiv"
        style={{
          position: 'absolute',
          left: '66.67%',
          top: 0,
          width: '16.67%',
          height: '100%',
        }}
      />
      <div
        id="iglootopdiv"
        style={{
          position: 'absolute',
          left: '83.33%',
          top: 0,
          width: '16.67%',
          height: '100%',
        }}
      />
    </div>
  )

  return (
    <div id="video-container" className="h-screen w-screen select-none">
      <LoadingPage visible={!loading.doneLoading} />
      <EndingPage visible={isEnding} />
      {!isEnding && (
        <a-scene
          id="aframe-scene"
          scene-settings
          raycaster="objects: [data-button]"
          custom-loading="name:aframe"
          keyboard-shortcuts="enterVR: false"
          xr-mode-ui="enabled: false"
          renderer="maxCanvasWidth: -1; maxCanvasHeight: -1"
        >
          <a-sky src={sky} radius="600" />
          <a-assets timeout="1000">
            <img src={arrow} id="direction-arrow" alt="" />
            <img src={clickDrag} id="icon-click-drag" alt="" />
            <img src={clickSelect} id="icon-click-select" alt="" />

            <img
              crossOrigin="anonymous"
              src={process.env.REACT_APP_IMAGE_URL + scenarioDetail.client.logoVrUrl}
              id="client-vr-logo"
              alt=""
            />
            <img
              crossOrigin="anonymous"
              src={process.env.REACT_APP_IMAGE_URL + scenarioDetail.client.logoMobileUrl}
              id="client-mobile-logo"
              alt=""
            />

            {selectedQuality && (
              <video
                ref={videoRef}
                onEnded={onEnded}
                onTimeUpdate={onTimeUpdate}
                onCanPlay={onCanPlay}
                onLoadedData={onVideoLoaded}
                onChange={onChange}
                crossOrigin="anonymous"
                id="video"
                src={videoUrl}
              />
            )}
          </a-assets>
          <a-videosphere
            custom-loading="name:videosphere;"
            key={scene.id}
            radius="50"
            rotation="0 -90 0"
            id="a-videosphere"
            src="#video"
          />

          <a-sphere id="camera-position" color="gray" radius="0.35" />
          <a-camera
            camera-controls
            custom-loading="name:camera;"
            fov="70"
            zoom="1"
            position="0 0 0"
            wasd-controls={`enabled: ${showDevTools};`}
            look-controls={`enabled: ${hasStarted}; reverseMouseDrag: true;`}
          >
            <Camera
              showDevTools={showDevTools}
              showSceneOrbs={showSceneOrbs}
              setShowSceneOrbs={setShowSceneOrbs}
              showNorthOffsetLine={showNorthOffsetLine}
              setShowNorthOffsetLine={setShowNorthOffsetLine}
              selectedQuality={selectedQuality}
              setSelectedQuality={setSelectedQuality}
            />
          </a-camera>
          {isImmersiveRoom && getImmersiveRoomCameras()}

          {/* Dev tools */}
          {showDevTools && getDevTools()}
          {currentSceneSettings.showQuestion && videoLoaded && (
            <Elements dispatchAttempt={dispatchAttempt} />
          )}
          {/* menu */}
          {isImmersiveRoom ? (
            <RoomHUD />
          ) : (
            <UserHUD isShowingQuestionFadeIn={isShowingQuestionFadeIn} />
          )}
          {!scenarioDetail.hideNadir && <Nadir />}
          {/* low internet speed banner */}
          <LowSpeedBanner />
        </a-scene>
      )}
      {!isEnding && isImmersiveRoom && getImmersiveRoomContainer()}

      {currentSceneSettings.showTimer && (
        <div id="timer" className="absolute bottom-24 z-10 w-screen shadow-sm lg:bottom-10">
          <div className="w-100 mx-auto h-4 bg-gray-800 md:w-96 md:rounded">
            <div
              ref={timerProgressRef}
              className="h-4 rounded-tr rounded-br bg-warp-red md:rounded"
              style={{ width: '100%' }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Player
