import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import WarpLogo from '../../WarpLogo'
import UserMenu from '../UserMenu'
import ScenarioPlayDevicesModal from '../../Scenario/ScenarioPlayDevices/ScenarioPlayDevicesModal'
import { AuthContext } from '../../../context/authContext'
import { isRoom } from '../../../helpers/browser'

const Header = ({ backgroundColor }) => {
  const { t } = useTranslation()
  const [showPlayModal, setShowPlayModal] = useState(false)
  const {
    sessionType: [sessionType],
  } = useContext(AuthContext)

  if (isRoom()) return null

  return (
    <div
      id="sticky-header"
      data-testid="header"
      className={`sticky top-0 z-10 flex w-full px-4 py-3 ${backgroundColor} ${
        sessionType !== 'LMS' && 'border-b'
      }`}
    >
      <div className="container mx-auto">
        <div className="flex w-auto items-center md:px-4">
          <WarpLogo />
          <div className="flex-1" id="spacer" />
          {sessionType === 'LOGIN' && (
            <>
              <button
                type="button"
                onClick={() => setShowPlayModal(true)}
                className="mr-6 hidden h-12 items-center justify-center rounded border px-4 text-sm font-bold text-warp-gray transition-all duration-200 hover:text-black lg:block"
              >
                {t('play_headset_phone_tablet', 'Play on VR headset, phone or tablet')}
              </button>
              <UserMenu />
              <ScenarioPlayDevicesModal
                show={showPlayModal}
                onClose={() => setShowPlayModal(false)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header

Header.propTypes = {
  backgroundColor: PropTypes.string,
}
Header.defaultProps = {
  backgroundColor: 'bg-white',
}
