import React, { createContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useLazyQuery } from '@apollo/client'
import { isBrightColor } from '../helpers/color'

const ScenarioContext = createContext()

const GET_SCENARIO_DETAILS = gql`
  query getScenarios($id: ID!) {
    scenarios(id: $id) {
      id
      fonts
      name
      startSceneId
      description
      testing
      highscore
      voiceEnabled
      locale
      client {
        id
        name
        appTitle
        appDescription
        logoVrUrl
        logoMobileUrl
        primaryColor
        secondaryColor
      }
      buyingClient {
        id
        name
        appTitle
        appDescription
      }
      imageUrl
      courseIds
      scenes {
        id
        name
        number
        description
        northOffset
        northOffsetEnabled
        hideHotspots
        hotspotHintEnabled
        hotspotHintAfter
        video {
          id
          play2kUrl
          play2kCdnUrl
          play4kUrl
          play4kCdnUrl
        }
        videoLoopEnabled
        videoLoop
        elementsFadeIn
        elements {
          id
          number
          kind
          anchorX
          anchorY
          groupKind
          groupUuid
          points
          linkToId
          linkToEnding
          randomizedLinkToIds
          label
          labelSize
          labelFont
          showLabel
          showInLookDirection
          hotspotSize
          timerMinutes
          timerSeconds
        }
      }
      hideNadir
      tutorial
      pdf {
        id
        name
        url
      }
      scoringSystem {
        kind
        pointsPerStar
        oneStarDescription
        twoStarDescription
        threeStarDescription
        fourStarDescription
        fiveStarDescription
        noStarDescription
      }
    }
  }
`

export const INITIAL_COLOR_STATE = {
  primaryColor: '#e7461a',
  secondaryColor: '#ffffff',
  text: '#000000',
}

const ScenarioProvider = ({ children }) => {
  const [colors, setColors] = useState(INITIAL_COLOR_STATE)
  const [scenarioDetail, setScenarioDetail] = useState()

  const [getScenarioDetail] = useLazyQuery(GET_SCENARIO_DETAILS, {
    onError: (error) => {
      throw new Error(error.message)
    },
    onCompleted: (data) => {
      setScenarioDetail(data.scenarios[0])
      setColors({
        primaryColor: data.scenarios[0].client.primaryColor,
        secondaryColor: data.scenarios[0].client.secondaryColor,
        text: isBrightColor(data.scenarios[0].client.primaryColor) ? 'black' : 'white',
      })
    },
  })

  const resetScenarioDetail = () => setScenarioDetail()

  const store = useMemo(
    () => ({
      colors,
      getScenarioDetail,
      resetScenarioDetail,
      scenarioDetail,
    }),
    [colors, scenarioDetail]
  )

  useEffect(() => {
    return () => {
      resetScenarioDetail()
    }
  }, [])

  return <ScenarioContext.Provider value={store}>{children}</ScenarioContext.Provider>
}

export { ScenarioContext, ScenarioProvider }

ScenarioProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
