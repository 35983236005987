import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { SceneContext } from '../../context/sceneContext'
import { ScenarioContext } from '../../context/scenarioContext'

const SceneInfoModal = ({ show, onClose }) => {
  const { t } = useTranslation()
  const { scene } = useContext(SceneContext)
  const { scenarioDetail } = useContext(ScenarioContext)
  const thisSceneIndex = scenarioDetail.scenes.findIndex((s) => s.id === scene.id)

  return (
    <Modal
      show={show}
      maxWidth="3xl"
      onClose={onClose}
      headerText={t('current_scene', 'Current scene')}
      closeButton
      footerText=" "
    >
      <div className="pb-14">
        <h2 className="mb-1 font-bold">
          {thisSceneIndex + 1}. {scene.name}
        </h2>
        {scene.description && <p className="text-warp-gray">{scene.description}</p>}
      </div>
    </Modal>
  )
}

SceneInfoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

SceneInfoModal.defaultProps = {
  onClose: () => {},
}

export default SceneInfoModal
