import React from 'react'
import { Outlet } from 'react-router-dom'
import NameBanner from '../components/NameBanner'
import MainLayout from './MainLayout'
import { ScenarioProvider } from '../context/scenarioContext'

const DashboardLayout = () => {
  return (
    <ScenarioProvider>
      <MainLayout>
        <div className="container mx-auto px-4 room:px-0">
          <NameBanner />
          <Outlet />
        </div>
      </MainLayout>
    </ScenarioProvider>
  )
}

export default DashboardLayout
