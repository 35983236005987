import { clamp } from '../../../../helpers/javascript'

const { AFRAME } = window

AFRAME.registerComponent('camera-controls', {
  init() {
    window.addEventListener('wheel', ({ wheelDelta }) => {
      const delta = wheelDelta / 120 / 10
      const zoom = this.el.getAttribute('zoom')
      this.el.setAttribute('zoom', clamp(Number(zoom) + delta, 1, 2))
    })
  },
})
