import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaTimes } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

const widths = ['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl']
const maxWidthsClasses = {
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
}

export const ModalCloseButton = ({ onClose }) => (
  <div className="absolute top-0 right-0 z-10 p-5 text-lg">
    <button type="button" label="close" onClick={onClose}>
      <div className={`rounded-50 ${'text-warp-gray'} p-2 transition-all `}>
        <FaTimes />
      </div>
    </button>
  </div>
)

const disableMouseWheel = (e) => {
  e.stopPropagation()
}

const Modal = React.forwardRef(
  (
    {
      show,
      onClose,
      closeButton,
      children,
      maxWidth,
      darkBackdrop,
      headerText,
      useDefaultButtons,
      buttons,
      submitDisabled,
      submitHandler,
      submitButtonText,
      id,
    },
    ref
  ) => {
    const { t } = useTranslation()
    // use escape to close modal
    const escapeHandler = (e) => {
      if (e.key === 'Escape') onClose()
    }

    const showCallback = () => {
      document.body.addEventListener('wheel', disableMouseWheel)
      document.addEventListener('keyup', escapeHandler)
      document.body.classList.add('overflow-hidden')
    }

    const hideCallback = () => {
      document.body.removeEventListener('wheel', disableMouseWheel)
      document.removeEventListener('keyup', escapeHandler)
      document.body.classList.remove('overflow-hidden')
    }

    useEffect(() => {
      if (show) showCallback()
      else hideCallback()
    }, [show])

    useEffect(() => hideCallback, [])

    if (!show) return <div data-testid="closed-modal" />

    const defaultButtons = (
      <>
        <button className="secondary button wide" onClick={onClose} type="button">
          {t('cancel', 'Cancel')}
        </button>
        <button
          type="submit"
          className="ml-2 text-white primary button wide"
          disabled={submitDisabled}
          onClick={submitHandler}
        >
          {submitButtonText || t('confirm', 'Confirm')}
        </button>
      </>
    )

    return (
      <div>
        <div
          className={`bg-black z-10 fixed top-0 left-0 h-screen w-screen ${
            !darkBackdrop ? 'lg:bg-opacity-80' : ''
          }`}
        />
        <div
          data-testid="modal"
          ref={ref}
          className="modal fixed room:absolute top-0 left-0 z-20 h-full w-full animate-modal-fade-in overflow-hidden cursor-auto"
          onClick={onClose}
          onKeyDown={onClose}
          role="button"
          tabIndex={0}
          id={id}
        >
          <div className="absolute inset-0 z-50 flex animate-scale-in items-start justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none lg:py-[60px]">
            <div
              className={`h-full w-full ${maxWidthsClasses[maxWidth]} md:m-6 md:h-auto cursor-auto room:scale-[0.65]`}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              role="button"
              tabIndex={0}
            >
              <div className="relative flex w-full flex-col overflow-hidden border-0 bg-white text-black shadow-lg lg:rounded">
                {closeButton && <ModalCloseButton onClose={onClose} />}
                {headerText && (
                  <h2 className="px-14 pt-14 pb-9 text-[26px] font-bold">{headerText}</h2>
                )}
                {children && <div className="px-14">{children}</div>}
                {(useDefaultButtons || buttons) && (
                  <div className="flex justify-end px-14 pb-14 pt-5">
                    {useDefaultButtons && defaultButtons}
                    {buttons}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

Modal.displayName = 'Modal'

export default Modal

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  show: PropTypes.bool.isRequired,
  closeButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(widths),
  darkBackdrop: PropTypes.bool,
  headerText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  useDefaultButtons: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.element),
  submitDisabled: PropTypes.bool,
  submitHandler: PropTypes.func,
  submitButtonText: PropTypes.string,
  id: PropTypes.string,
}

ModalCloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
}

Modal.defaultProps = {
  closeButton: true,
  maxWidth: '6xl',
  darkBackdrop: false,
  headerText: '',
  useDefaultButtons: false,
  buttons: null,
  submitDisabled: false,
  submitHandler: () => {},
  submitButtonText: '',
  id: null,
}
