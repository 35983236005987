import React, { createContext, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'

const initialLoadingState = {
  doneLoading: false,
  isLoadingData: true,
  isLoading: {
    scenario: true,
    scene: true,
    attemptsController: true,
    aframe: true,
    camera: true,
    video: true,
    font: true,
  },
}
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_LOADING_STATE':
      // action.value should be an object. e.g.: {camera: false}
      const newState = { ...state, isLoading: { ...state.isLoading, ...action.value } }
      const { scenario, scene, font, aframe } = newState.isLoading
      if (state.isLoadingData && !scenario && !scene) newState.isLoadingData = false
      if (!font && !aframe) newState.doneLoading = true
      return newState
    case 'LOADING_DATA_DONE':
      return { ...state, isLoadingData: false }
    case 'RESET_LOADING_STATE':
      return initialLoadingState
    default:
      return null
  }
}
const LoadContext = createContext()

const LoadProvider = ({ children }) => {
  const [loading, dispatch] = useReducer(reducer, initialLoadingState)

  const store = useMemo(
    () => ({
      loading,
      dispatch,
    }),
    [loading]
  )

  return <LoadContext.Provider value={store}>{children}</LoadContext.Provider>
}

export { LoadContext, LoadProvider }
LoadProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
