import React, { useContext, useEffect, useRef, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { FaPlay } from 'react-icons/fa'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Tab from './ScenarioPlayDevicesTab'
import metaLogo from '../../../assets/images/logos/icon-meta.svg'
import picoLogo from '../../../assets/images/logos/icon-pico-colored.svg'
import appleLogo from '../../../assets/images/logos/icon-apple.svg'
import googleLogo from '../../../assets/images/logos/icon-google-play2.svg'
import appleQR from '../../../assets/images/qr-codes/app-store.svg'
import googleQR from '../../../assets/images/qr-codes/google-play.svg'
import { AuthContext } from '../../../context/authContext'
import { isBrowserSupported, isMobile } from '../../../helpers/browser'
import { ScenarioContext } from '../../../context/scenarioContext'

const CREATE_SESSION = gql`
  mutation CreateSession {
    createSession {
      session {
        id
        launchCode
      }
    }
  }
`

const CREATE_SESSION_WITH_SCENARIO = gql`
  mutation CreateSession($scenarioId: ID) {
    createSession(scenarioId: $scenarioId) {
      session {
        id
        launchCode
      }
    }
  }
`

const UPDATE_SESSION = gql`
  mutation updateSession($event: SessionEvent!) {
    updateSession(event: $event) {
      session {
        id
        launchCode
      }
    }
  }
`

const APP_STORE = 'https://apps.apple.com/nl/app/warp-vr/id1280278605'
const GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=com.warpindustries.warpvrcb&hl=nl&gl=US'

const ScenarioPlayDevices = ({ anonymousMode, course }) => {
  const { t } = useTranslation()
  const [selectedDevice, _setSelectedDevice] = useState()
  const [selectedSection, setSelectedSection] = useState()
  const [launchCode, setLaunchCode] = useState()
  const formRef = useRef()
  const {
    sessionType: [sessionType],
  } = useContext(AuthContext)
  const { colors, scenarioDetail } = useContext(ScenarioContext)

  const [_createSession] = useMutation(CREATE_SESSION, {
    onCompleted: (data) => {
      setLaunchCode(data.createSession.session.launchCode)
    },
  })
  const [_createSessionWithScenario] = useMutation(CREATE_SESSION_WITH_SCENARIO, {
    variables: { scenarioId: scenarioDetail?.id },
    onCompleted: (data) => {
      setLaunchCode(data.createSession.session.launchCode)
    },
  })

  const createSession = () => {
    if (scenarioDetail) return _createSessionWithScenario()
    return _createSession()
  }

  const [updateSessionMutation] = useMutation(UPDATE_SESSION, { variables: { event: 'RESET' } })

  const setSelectedDevice = async (device) => {
    if (sessionType === 'LMS') {
      if (!selectedDevice) {
        const { data } = await updateSessionMutation()
        setLaunchCode(data.updateSession.session.launchCode)
      }
      _setSelectedDevice(device)
    } else _setSelectedDevice(device)
  }

  useEffect(() => {
    if (sessionType !== 'LMS') createSession()
  }, [scenarioDetail?.id])

  useEffect(() => {
    if (selectedDevice) formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [selectedDevice])

  const getSelectedDeviceTabs = () => {
    const devices = {
      VR: (
        <>
          <p className="mt-2 text-base text-warp-gray">
            {t('click_download_instructions', 'Click for download instructions')}
          </p>
          <div className="mt-2 flex">
            <Tab
              onClick={() => setSelectedSection('meta')}
              selected={selectedSection === 'meta'}
              name="section"
              value="meta"
            >
              <div className="flex h-full w-full items-center justify-start">
                <img src={metaLogo} alt="Meta Quest" className="mx-2 h-7 w-7 lg:mx-4" />
                <span className="text mb-0.5 text-sm font-semibold">Meta Quest</span>
              </div>
            </Tab>
            <div className="px-1" />
            <Tab
              onClick={() => setSelectedSection('pico')}
              selected={selectedSection === 'pico'}
              name="section"
              value="pico"
            >
              <div className="flex h-full w-full items-center justify-start">
                <img
                  src={picoLogo}
                  alt="Pico"
                  className={`mx-2 h-7 w-7 lg:mx-4 ${
                    selectedSection === 'pico' ? 'brightness-0' : ''
                  }`}
                />
                <span className="text mb-0.5 text-sm font-semibold">Pico</span>
              </div>
            </Tab>
          </div>
        </>
      ),
      phone: (
        <>
          <p className="mt-2 text-base text-warp-gray">
            {t('click_download_instructions', 'Click for download instructions')}
          </p>
          <div className="mt-2 flex">
            <Tab
              onClick={() => setSelectedSection('apple')}
              name="section"
              value="apple"
              selected={selectedSection === 'apple'}
            >
              <a
                className="pointer-events-auto flex h-full w-full items-center justify-start lg:pointer-events-none"
                href={APP_STORE}
                target="_blank"
                rel="noreferrer"
              >
                <img src={appleLogo} alt="App store" className="mx-2 h-7 w-7 lg:mx-4" />
                <span className="text mb-0.5 text-sm font-semibold">App Store</span>
              </a>
            </Tab>
            <div className="px-1" />
            <Tab
              onClick={() => setSelectedSection('google')}
              name="section"
              value="google"
              selected={selectedSection === 'google'}
            >
              <a
                className="pointer-events-auto flex h-full w-full items-center justify-start lg:pointer-events-none"
                href={GOOGLE_PLAY}
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleLogo} alt="Google Play" className="mx-2 h-7 w-7 lg:mx-4" />
                <span className="text mb-0.5 text-sm font-semibold">Google Play</span>
              </a>
            </Tab>
          </div>
        </>
      ),
      desktop: (
        <p className="mt-2 text-base text-warp-gray">
          <Trans
            i18nKey="open_browser_go"
            defaults="Open your browser and go to <a>play.warpvr.com</a>"
            components={{
              a: (
                <a target="_blank" rel="noreferrer" className="text-white">
                  play.warpvr.com
                </a>
              ),
            }}
          />
        </p>
      ),
    }
    return devices[selectedDevice]
  }

  const getInputCode = (ch) => (
    <div className="mr-2 flex h-[62px] w-full items-center justify-center rounded bg-warp-gray bg-white/10 text-2xl font-bold last:mr-0">
      {ch}
    </div>
  )

  const getDeviceTitle = () => {
    const titles = {
      desktop: t('go_warp_desktop', 'Go to Warp VR on your desktop or laptop'),
      VR: t('install_warp_on_headset', 'Install Warp VR on your VR headset'),
      phone: t('install_warp_on_phone_or_tablet', 'Install Warp VR on your phone or tablet'),
    }
    return titles[selectedDevice]
  }

  const sections = {
    meta: (
      <div className="mt-4">
        <h4 className="font-bold">Meta Quest</h4>
        <p className="text-warp-gray">
          <Trans
            i18nKey="search_meta"
            defaults="Search for <b>Warp VR</b> in the Meta Quest store and install."
            components={{
              b: <span className="font-bold">Warp VR</span>,
            }}
          />
          &nbsp;
          <a
            className="underline"
            target="_blank"
            href="https://help.warpvr.com/warp-vr-app/dGeYFqqKNHX4jRRmyRr75X/%F0%9F%A5%BD-meta-quest-headsets/e68rJmE85i7BPLBYpuKiYD"
            rel="noreferrer"
          >
            {t('more_instructions_here', 'More instructions here.')}
          </a>
        </p>
      </div>
    ),
    pico: (
      <div className="mt-4">
        <h4 className="font-bold">Pico</h4>
        <p className="text-warp-gray">
          <Trans
            i18nKey="search_pico"
            defaults="Search for <b>Warp VR</b> in the Pico store and install."
            components={{
              b: <span className="font-bold">Warp VR</span>,
            }}
          />
          <a
            className="underline"
            target="_blank"
            href="https://help.warpvr.com/warp-vr-app/dGeYFqqKNHX4jRRmyRr75X/%F0%9F%A4%BF-pico-headsets-/fUCSVEr1VZ9F4hUNSAVafj"
            rel="noreferrer"
          >
            {t('more_instructions_here', 'More instructions here.')}
          </a>
        </p>
      </div>
    ),
    apple: (
      <div className="mt-4 hidden items-start lg:flex">
        <div>
          <a href={APP_STORE} className="mr-5 block w-[92px] pt-1">
            <img src={appleQR} className="block w-full" alt="Apple Store" />
          </a>
        </div>
        <div>
          <h4 className="font-bold">App Store</h4>
          <p className="text-warp-gray">
            <Trans
              i18nKey="search_apple_store"
              defaults="Search for <b>Warp VR</b> in the App Store. Or scan this QR code with your phone or tablet"
              components={{
                b: <span className="font-bold">Warp VR</span>,
              }}
            />
          </p>
        </div>
      </div>
    ),
    google: (
      <div className="mt-4 hidden items-start lg:flex">
        <div>
          <a href={GOOGLE_PLAY} className="mr-5 block w-[92px] pt-1">
            <img src={googleQR} className="block w-full" alt="Google Play" />
          </a>
        </div>
        <div>
          <h4 className="font-bold">Google Play</h4>
          <p className="text-warp-gray">
            <Trans
              i18nKey="search_google_play"
              defaults="Search for <b>Warp VR</b> in the Google Play. Or scan this QR code with your phone or tablet"
              components={{
                b: <span className="font-bold">Warp VR</span>,
              }}
            />
          </p>
        </div>
      </div>
    ),
  }

  // it will be used when users want to back from scenario
  const getReturnUrl = () => {
    let url = '/'
    if (course) url += `courses/${course.id}`
    else url += scenarioDetail?.testing ? 'tests' : 'scenarios'
    return `${url}/${scenarioDetail?.id}/modal`
  }

  const returnUrl = getReturnUrl()
  return (
    <>
      <div className="hidden room:flex w-full">
        <Tab colors={colors} primary={!selectedDevice}>
          <Link
            to={`/scenarios/${scenarioDetail?.id}/play?returnUrl=${returnUrl}`}
            className="flex h-full w-full items-center justify-center"
          >
            <FaPlay className="mr-2" />
            {t('start_scenario', 'Start scenario')}
          </Link>
        </Tab>
      </div>
      <div className="room:hidden flex flex-col w-full text-center">
        {anonymousMode && isMobile() && (
          <p className="mb-4 w-full rounded bg-warp-yellow px-6 py-5 text-left text-black">
            {t(
              'public_scenarios_desktop_msg',
              'For now, public scenarios can only be played on desktop or laptop.'
            )}
          </p>
        )}

        {!anonymousMode && !isMobile() && !isBrowserSupported() && (
          <p className="mb-4 w-full rounded bg-warp-yellow px-6 py-5 text-left text-black">
            {t(
              'play_scenario_browser_supported_msg',
              'For now playing in a web browser is only supported on Chrome, Edge and Firefox.'
            )}
          </p>
        )}

        {!anonymousMode && (
          <p className="mb-1 font-bold text-left">
            {t('play_this_scenario_on', 'Play this scenario on')}
          </p>
        )}

        {isBrowserSupported() && !isMobile() && scenarioDetail && (
          <Tab colors={colors} primary={!selectedDevice}>
            <Link
              to={`/scenarios/${scenarioDetail.id}/play?returnUrl=${returnUrl}`}
              className="flex h-full w-full items-center justify-center"
            >
              <FaPlay className="mr-2" />
              {t('in_this_browser', 'In this browser')}
            </Link>
          </Tab>
        )}

        {!anonymousMode && (
          <>
            {scenarioDetail && !isMobile() && (
              <Tab
                onClick={() => {
                  setSelectedDevice('desktop')
                  setSelectedSection(null)
                }}
                selected={selectedDevice === 'desktop'}
                className={`${isBrowserSupported() && 'lg:hidden'}`}
                name="device"
                value="desktop"
                primary={!selectedDevice}
              >
                {t('switch_browser', 'Switch browser')}
              </Tab>
            )}

            {isMobile() && (
              <Tab
                onClick={() => {
                  setSelectedDevice('phone')
                  setSelectedSection(null)
                }}
                selected={selectedDevice === 'phone'}
                name="device"
                value="phone"
                primary={!selectedDevice || selectedDevice === 'phone'}
              >
                {t('phone_or_tablet', 'Phone or tablet')}
              </Tab>
            )}

            <div className="w-full text-left">
              <div className="flex w-full">
                <Tab
                  onClick={() => {
                    setSelectedDevice('VR')
                    setSelectedSection(null)
                  }}
                  selected={selectedDevice === 'VR'}
                  name="device"
                  value="VR"
                  primary={!selectedDevice || selectedDevice === 'VR'}
                >
                  {t('vr_headset', 'VR headset')}
                </Tab>

                <div className="px-1.5" />

                {isMobile() && (
                  <Tab
                    onClick={() => {
                      setSelectedDevice('desktop')
                      setSelectedSection(null)
                    }}
                    selected={selectedDevice === 'desktop'}
                    name="device"
                    value="desktop"
                    primary={!selectedDevice || selectedDevice === 'desktop'}
                  >
                    {t('desktop_laptop', 'Desktop or laptop')}
                  </Tab>
                )}

                {!isMobile() && (
                  <Tab
                    onClick={() => {
                      setSelectedDevice('phone')
                      setSelectedSection(null)
                    }}
                    selected={selectedDevice === 'phone'}
                    name="device"
                    value="phone"
                    primary={!selectedDevice}
                  >
                    {t('phone_or_tablet', 'Phone or tablet')}
                  </Tab>
                )}
              </div>

              {selectedDevice && (
                <form className="mt-10 min-h-[calc(100vh-200px)] md:min-h-0" ref={formRef}>
                  <h3 className="flex items-center text-lg font-bold text-warp-yellow">
                    <div className="mr-3 flex h-6 min-w-[24px] items-center justify-center rounded-50 bg-warp-yellow text-black">
                      1
                    </div>
                    {getDeviceTitle()}
                  </h3>

                  {getSelectedDeviceTabs()}

                  {sections?.[selectedSection]}

                  <h3 className="mt-8 flex items-center text-lg font-bold text-warp-yellow">
                    <div className="mr-3 flex h-6 min-w-[24px] items-center justify-center rounded-50 bg-warp-yellow text-black">
                      2
                    </div>
                    {t('use_code_to_sign_in', 'Use 6-digit code to sign in')}
                  </h3>
                  <p className="mt-2 text-base text-warp-gray">
                    {t('code_is_valid_for_mins', {
                      defaultValue: 'This code is valid for 60 minutes and can only be used once.',
                      mins: 60,
                    })}
                  </p>
                  {launchCode && (
                    <div className="mt-3 flex items-center justify-between">
                      {getInputCode(launchCode[0])}
                      {getInputCode(launchCode[1])}
                      {getInputCode(launchCode[2])}
                      <span className="mr-3 ml-1 select-none text-2xl font-bold">-</span>
                      {getInputCode(launchCode[3])}
                      {getInputCode(launchCode[4])}
                      {getInputCode(launchCode[5])}
                    </div>
                  )}
                  <h3 className="mt-8 flex items-center text-lg font-bold text-warp-yellow">
                    <div className="mr-3 flex h-6 min-w-[24px] items-center justify-center rounded-50 bg-warp-yellow text-black">
                      3
                    </div>
                    {t('start_playing', 'Start playing')}
                  </h3>
                </form>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ScenarioPlayDevices

ScenarioPlayDevices.propTypes = {
  anonymousMode: PropTypes.bool,
  colors: PropTypes.shape({
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
  course: PropTypes.shape(),
}

ScenarioPlayDevices.defaultProps = {
  anonymousMode: false,
  colors: {},
  course: null,
}
