import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'

const LowSpeedBanner = () => {
  const { t } = useTranslation()
  const [hide, setHide] = useState(navigator?.connection?.effectiveType === '4g')
  return (
    <>
      {!hide && (
        <div
          id="low-speed-banner"
          data-testid="low-speed-banner"
          className="container absolute left-2/4 z-10 mt-10 flex h-[60px] w-full translate-x-[-50%] items-center justify-between rounded bg-warp-yellow pl-[30px] text-base text-black"
        >
          {t(
            'detect_slow_connection_message',
            'We detect a slow internet connection, which could affect playing this scenario. Please check your internet settings.'
          )}
          <button
            type="button"
            onClick={setHide}
            label="close"
            className="h-12 w-12 text-black hover:cursor-pointer"
          >
            <FaTimes className="text-xl" title="close" />
          </button>
        </div>
      )}
    </>
  )
}

export default LowSpeedBanner
