import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal, { ModalCloseButton } from '../Modal'
import ClientLogo from '../UI/ClientLogo'

const imageUrl = process.env.REACT_APP_IMAGE_URL

const AboutOrganizationModal = ({ showModal, client, onClose }) => {
  const { t } = useTranslation()
  const ref = useRef()

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      headerText={<ClientLogo src={`${imageUrl}${client.logoMobileUrl}`} />}
      ref={ref}
      id="about-org-modal"
    >
      <div className="pb-14">
        <ModalCloseButton onClose={onClose} />
        <h2 className="text-[26px] font-bold">{client.appTitle}</h2>
        <h3 className="mt-6 font-semibold">{t('about_org', 'About this organization')}</h3>
        <p className="text-warp-gray">{client.appDescription}</p>
      </div>
    </Modal>
  )
}
export default AboutOrganizationModal

AboutOrganizationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  client: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
}
