import React, { useContext, useState, useEffect } from 'react'
import { FaChevronDown, FaCog, FaChevronRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../context/userContext'
import Dropdown from './Dropdown'
import { LANGS } from '../../i18n'
import LanguageMenuItems from './LanguageMenuItems'
import EditProfile from '../Profile/EditProfile'

const UserMenu = () => {
  const { i18n, t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  const [showLangMenu, setShowLangMenu] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(false)
  const [showEditProfile, setShowEditProfile] = useState(false)
  const {
    user: [user],
    logout,
  } = useContext(UserContext)

  useEffect(() => {
    setSelectedLanguage(LANGS.find((l) => l.key === i18n.language))
  }, [i18n.language])

  return (
    <>
      <div className="relative inline-block">
        <button
          type="button"
          label="menu"
          className={`${
            showMenu ? 'border border-black text-black shadow-md' : 'border text-warp-gray'
          } flex h-12 items-center justify-center px-4 transition-all duration-200 hover:text-black hover:shadow-md`}
          onClick={() => setShowMenu(!showMenu)}
        >
          <FaCog className="mr-2" />
          <FaChevronDown />
        </button>
        <Dropdown arrow isVisible={showMenu} toggleVisible={setShowMenu}>
          <>
            <div className="py-1 border-b">
              <div className="block w-full px-4 py-2 text-left text-sm text-warp-gray">
                <div>
                  <h2 className="mt-1 mb-1 font-bold text-black">{user.name}</h2>
                  <div className="max-w-full overflow-hidden text-ellipsis font-semibold">
                    {user.email}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                className="relative py-1"
                onMouseEnter={() => setShowLangMenu(true)}
                onMouseLeave={() => setShowLangMenu(false)}
              >
                <button
                  type="button"
                  className="flex w-full items-center justify-between px-4 py-2 text-left text-sm transition-all duration-150 hover:bg-warp-red hover:bg-opacity-10 rounded-none"
                >
                  <span>{t('set_language', 'Set language')}</span>
                  <div className="flex items-center">
                    {selectedLanguage.value}
                    <FaChevronRight className="ml-1 text-warp-gray" />
                  </div>
                </button>
                <LanguageMenuItems
                  isVisible={showLangMenu}
                  toggleVisible={setShowLangMenu}
                  position="left"
                />
              </div>

              <div className="py-1">
                <button
                  type="button"
                  className="block w-full px-4 py-2 text-left text-sm transition-all duration-150 hover:bg-warp-red hover:bg-opacity-10 rounded-none"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-3"
                  onClick={() => setShowEditProfile(true)}
                >
                  {t('edit_profile', 'Edit profile')}
                </button>
              </div>
            </div>

            <div className="py-1 border-t">
              <button
                type="button"
                className="block w-full px-4 py-2 text-left text-sm transition-all duration-150 hover:bg-warp-red hover:bg-opacity-10 rounded-none"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-4"
                onClick={logout}
              >
                {t('sign_out_button', 'Sign out')}
              </button>
            </div>
          </>
        </Dropdown>
      </div>
      <EditProfile show={showEditProfile} onClose={() => setShowEditProfile(false)} />
    </>
  )
}
export default UserMenu
