const { AFRAME } = window

AFRAME.registerComponent('custom-loading', {
  schema: {
    name: { type: 'string', default: '' },
  },
  init() {
    const doneLoading = new CustomEvent('custom-loading', {
      detail: {
        name: this.data.name,
      },
    })
    document.dispatchEvent(doneLoading)
  },
})
