import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Header from '../components/UI/Header/Header'
import FeedbackButton from '../components/FeedbackButton/FeedbackButton'
import { UserContext } from '../context/userContext'
import { isRoom } from '../helpers/browser'

const MainLayout = ({ backgroundColor, children }) => {
  const {
    user: [user],
    lmsScenarioId,
  } = useContext(UserContext)

  return (
    <>
      <div id="container" className="h-max w-full">
        <Header backgroundColor={backgroundColor} />
        <div
          id="page-content"
          className={`mb-48 md:mb-[100px] ${!lmsScenarioId && 'xl:mb-[200px]'} room:mb-0`}
        >
          {children}
        </div>
        {!isRoom() && user.email && (
          <div className="fixed bottom-10 right-12 z-10">
            <FeedbackButton email={user.email} />
          </div>
        )}
      </div>
      <div id="bg" className={`fixed top-0 left-0 -z-10 h-full w-full ${backgroundColor}`} />
    </>
  )
}

export default MainLayout

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  backgroundColor: PropTypes.string,
}
MainLayout.defaultProps = {
  backgroundColor: 'bg-white',
}
