import React, { useContext } from 'react'
import { v4 as uuidV4 } from 'uuid'
import PropTypes from 'prop-types'
import { ScenarioContext } from '../../../context/scenarioContext'
import { SceneContext } from '../../../context/sceneContext'
import Button from './partials/Button'
import TextBubble from './partials/TextBubble'
import { getRotation, getShowInLookDirectionRotation } from '../helpers/controls'

const MultipleChoice = ({ element, childElements }) => {
  const { colors } = useContext(ScenarioContext)
  const { scene, currentSceneSettings, handleSceneChange, dispatchAttempt, handleEnding } =
    useContext(SceneContext)

  const handleRotation = () => {
    if (element.showInLookDirection) return `${getShowInLookDirectionRotation()} 0`
    return `${getRotation({ x: element.anchorX, y: element.anchorY })} 0`
  }

  let BUBBLE_WIDTH = 1.8
  let BUTTON_WIDTH = 2.75
  if (element.labelSize === 'WIDE') BUBBLE_WIDTH *= 2
  if (childElements[0].labelSize === 'WIDE') BUTTON_WIDTH *= 2

  const getMpcAnswers = () => {
    return childElements.map((chEl, index) => (
      <Button
        clickHandler={() => {
          dispatchAttempt({
            type: 'CHOICE',
            value: { sceneId: scene.id, answerId: chEl.id },
            uuid: uuidV4(),
          })
          if (chEl.linkToEnding) return handleEnding()
          return handleSceneChange(chEl.linkToId)
        }}
        textValue={chEl.label || 'Answer'}
        font={chEl.labelFont}
        buttonColor={colors.primaryColor}
        textColor={colors.text}
        id={`Answer-${index}`}
        type="mpc"
        key={`btn-${chEl.id}`}
        width={BUTTON_WIDTH}
      />
    ))
  }

  return (
    <a-entity
      id="multiple-choice"
      key={uuidV4()}
      hideable-element={`hidden: ${!currentSceneSettings.showQuestion}`}
      rotation={handleRotation()}
    >
      <a-entity position="0 0 -7" rotation="0 5 0">
        <TextBubble
          textValue={element.label || 'Question'}
          font={element.labelFont}
          id="sceneQuestion"
          type="mpc"
          width={BUBBLE_WIDTH}
          bottomSpacing="0.1"
        />
        <a-entity
          id="mpc-list"
          mpc={`elementSize: ${element.labelSize}; answerElementSize: ${childElements[0].labelSize}`}
        >
          {getMpcAnswers()}
        </a-entity>
      </a-entity>
    </a-entity>
  )
}
export default MultipleChoice

MultipleChoice.propTypes = {
  element: PropTypes.shape.isRequired,
  childElements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}
