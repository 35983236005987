import React, { useContext, useState } from 'react'
import { FaPause, FaPlay, FaVolumeMute, FaVolumeUp } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { SceneContext } from '../../../../context/sceneContext'
import { moveCameraToPosition } from '../../helpers/controls'

const QUALITIES = ['2k', '2kCdn', '4k', '4kCdn']

const VideoInformation = ({ selectedQuality, setSelectedQuality }) => {
  const [muted, setMuted] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const { scene } = useContext(SceneContext)

  if (!scene) return null

  const handlePlay = () => {
    const video = document.querySelector('#video')

    if (video.paused) video.play()
    else video.pause()

    setIsPaused(video.paused)
  }
  const handleMute = () => {
    const video = document.querySelector('#video')
    video.muted = !muted
    setMuted(!muted)
  }

  return (
    <div className="absolute right-0 z-10 m-3 text-xs">
      <div className=" min-h-10 h-auto w-72 ">
        <div className="mb-3 rounded border-4  border-[gray-400] bg-gray-800 bg-opacity-50 px-4 py-2 text-white">
          <p className="mb-3 font-bold">Video</p>
          <div>
            video id: {scene?.video.id}
            <br />
          </div>
          <div>
            <li key="item-quality" className="flex">
              <span className="mr-10 flex-grow">quality</span>
              <div id="toggle">
                <span>hi</span>
              </div>
              <span className="text-right">2k </span>
            </li>
          </div>
          <div className="mb-3 flex gap-2">
            <button
              type="button"
              title="Toggle mute"
              className="rounded  bg-slate-300 px-4 py-2 text-sm transition-all button hover:bg-slate-500 hover:shadow-md"
              onClick={handleMute}
            >
              {muted ? <FaVolumeUp /> : <FaVolumeMute />}
            </button>

            <button
              type="button"
              className="rounded bg-slate-300 px-4 py-2 text-sm transition-all button hover:bg-slate-500 hover:shadow-md"
              onClick={handlePlay}
            >
              {isPaused ? <FaPlay /> : <FaPause />}
            </button>
            <div className="flex flex-col">
              {isPaused && <div className=" items-center">Video paused</div>}
              {muted && <div className="items-center">Video muted</div>}
            </div>
          </div>

          <div className="mb-3">
            {QUALITIES.map((q) => {
              const disabled = !scene?.video?.[`play${q}Url`]
              return (
                <button
                  key={q}
                  type="button"
                  title={q}
                  className={`mr-1 rounded bg-slate-300 px-3 py-1 text-sm transition-all button disabled:opacity-70
                    ${selectedQuality === q && 'bg-slate-500'}
                    ${!disabled && 'hover:bg-slate-500 hover:shadow-md'}
                  `}
                  disabled={disabled}
                  onClick={() => setSelectedQuality(q)}
                >
                  {q}
                </button>
              )
            })}
          </div>

          <div>
            <button
              type="button"
              className="w-full rounded bg-slate-300 px-4 py-2 transition-all button hover:bg-slate-500 hover:shadow-md"
              onClick={() => {
                document.querySelector('a-camera').setAttribute('position', '0 0 0')
                moveCameraToPosition(0, scene.northOffsetEnabled ? -scene.northOffset : 0)
              }}
            >
              Reset camera
            </button>
          </div>
        </div>
      </div>
      <div className=" min-h-10 h-auto w-72 ">
        <div className="mb-3 rounded border-4  border-[gray-400] bg-gray-800 bg-opacity-50 px-4 py-2 text-white">
          <p className="mb-3 font-bold">Client</p>
          <ul>
            <li className="flex">
              <span className="mr-10 flex-grow">Mbit: </span>
              <span>{navigator?.connection?.downlink}</span>
            </li>
            <li className="flex">
              <span className="mr-10 flex-grow">Mbit (max): </span>
              <span>{navigator?.connection?.downlinkMax}</span>
            </li>
            <li className="flex">
              <span className="mr-10 flex-grow">rtt: </span>
              <span>{navigator?.connection?.rtt} ms</span>
            </li>
            <li className="flex">
              <span className="mr-10 flex-grow">type: </span>
              <span className="text-right">{navigator?.connection?.type}</span>
            </li>
            <li className="flex">
              <span className="mr-10 flex-grow">effectivetype: </span>
              <span className="text-right">{navigator?.connection?.effectiveType}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default VideoInformation

VideoInformation.propTypes = {
  selectedQuality: PropTypes.string.isRequired,
  setSelectedQuality: PropTypes.func.isRequired,
}
