import { clamp } from '../../../../helpers/javascript'
import { arrowBubble } from '../../helpers/shapes'

const { AFRAME, THREE } = window

AFRAME.registerComponent('text-bubble', {
  schema: {
    enabled: { default: true },
    radius: { default: 0.05 },
    textOffset: { default: 0.05 },
    paddingX: { default: 0.1 },
    paddingY: { default: 0.2 },
    bottomSpacing: { default: 0.15 },
    width: { default: 1.8 },
    type: { default: 'default' },
  },
  drawTextBubble() {
    const shape = new THREE.Shape()
    arrowBubble(
      shape,
      -this.data.width / 2,
      -(this.data.height / 2) + this.data.textOffset,
      this.data.width,
      this.data.height + this.data.textOffset,
      this.data.radius || 0.05
    )
    return new THREE.ShapeGeometry(shape)
  },

  getTextBubble() {
    const textBox = this.el.children[0]
    const bubbleBox = this.el.children[1]
    if (!textBox || !bubbleBox) return null

    let { height } = textBox.getAttribute('geometry')
    if (!height) return null

    height += this.data.paddingY * 2
    this.data.height = clamp(height, 0.3, height)

    this.el.setAttribute('position', `0 ${this.data.height / 2 + this.data.bottomSpacing} 0`)

    this.bubble = new THREE.Mesh(this.drawTextBubble())
    this.bubble.position.set(0, 0, 0.01)
    textBox.setAttribute('position', `0 ${this.data.paddingY - 0.02} 0.03`)

    return bubbleBox.setObject3D('bubble', this.bubble)
  },

  loaded() {
    const child = this.el.children[0]
    child.addEventListener('componentchanged', (e) => {
      if (e.detail.name === 'geometry') {
        const geometry = child.getAttribute('geometry')
        if (geometry.width && geometry.height) {
          this.getTextBubble()
          child.setAttribute('visible', true)
        }
      }
      if (e.detail.name === 'text') {
        setTimeout(() => this.getTextBubble(), 0)
        this.el.children[0].setAttribute('geometry', `primitive: plane; width: auto; height: auto;`)
        setTimeout(() => this.getTextBubble(), 0)
      }
    })
  },
  init() {
    this.el.children[0].setAttribute('visible', false)
    this.el.addEventListener('loaded', () => this.loaded())
  },
})
