const { AFRAME } = window

AFRAME.registerComponent('hideable-element', {
  schema: {
    hidden: { type: 'boolean', default: true },
  },
  update() {
    this.el.setAttribute('visible', !this.data.hidden)
  },
  init() {
    this.el.setAttribute('visible', false)
  },
})
