import React from 'react'
import PropTypes from 'prop-types'

const Tooltip = ({ title, children, position }) => {
  const positionClasses = {
    top: 'bottom-[80%] group-hover:mb-[15px] group-hover:bottom-full',
    bottom: 'top-[80%] group-hover:mt-[15px] group-hover:top-full',
  }
  const arrowClasses = {
    top: 'border-t-[10px] bottom-[-10px] border-t-white/90',
    bottom: 'border-b-[10px] top-[-10px] border-b-white/90',
  }
  return (
    <div className="group relative">
      <div
        className={`absolute left-2/4 translate-x-[-50%] rounded bg-white/90 opacity-0 transition-all delay-500 ease-in group-hover:opacity-100 ${positionClasses[position]}`}
      >
        <div
          className={`border-bottom-0 absolute left-2/4 ml-[-10px] h-0 w-0 border-x-[10px] border-solid border-x-transparent border-b-transparent ${arrowClasses[position]}`}
        />
        <div className="whitespace-nowrap px-5 py-4 text-slate-800 text-sm text-normal">
          {title}
        </div>
      </div>
      {children}
    </div>
  )
}

export default Tooltip

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  position: PropTypes.string,
}

Tooltip.defaultProps = {
  position: 'top',
}
