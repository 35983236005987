import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidV4 } from 'uuid'
import { ScenarioContext } from './scenarioContext'
import { LoadContext } from './loadContext'
import useAttempt from '../helpers/hooks/useAttempt'
import { isRoom } from '../helpers/browser'

export const ELEMENTS_STORAGE_KEY = 'testingElementsStatus'
const SceneContext = createContext()

const SceneProvider = ({ children }) => {
  const initialSceneSettings = {
    showQuestion: false,
    showHint: false,
    showTimer: false,
  }
  const [scene, setScene] = useState(null)
  const [isEnding, setIsEnding] = useState(false)
  const [isAborted, setIsAborted] = useState(false)
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [currentSceneSettings, setCurrentSceneSettings] = useState(initialSceneSettings)
  const [sceneSeries, setSceneSeries] = useState([])
  const [hasStarted, setHasStarted] = useState(false)
  const [isImmersiveRoom, setIsImmersiveRoom] = useState(false)

  const { scenarioDetail } = useContext(ScenarioContext)
  const { dispatch } = useContext(LoadContext)
  const [attemptState, dispatchAttempt] = useAttempt()

  const restartScene = () => {
    setCurrentSceneSettings({ ...currentSceneSettings, showQuestion: false, showHint: false })
    document.querySelector('#video').currentTime = 0
  }

  const showQuestionsOnTestMode = () =>
    scenarioDetail.testing && localStorage.getItem(ELEMENTS_STORAGE_KEY) === 'always_on'

  const handleEnding = () => {
    dispatchAttempt({ type: 'ENDING', uuid: uuidV4() })
    return setIsEnding(true)
  }

  const handleSceneChange = (nextSceneId, trackScene = true) => {
    if (!nextSceneId) return false  
    const thisScene = scenarioDetail.scenes.find((s) => s.id === nextSceneId)
    setScene(thisScene)

    if (trackScene) setSceneSeries([...sceneSeries, nextSceneId])

    setCurrentSceneSettings({
      ...currentSceneSettings,
      showQuestion: hasStarted && showQuestionsOnTestMode(),
      showHint: false,
      showTimer: false,
    })

    return dispatch({ type: 'UPDATE_LOADING_STATE', value: { scene: false } })
  }

  const previousScene = () => {
    handleSceneChange(sceneSeries[sceneSeries.length - 2], false)
    setSceneSeries(sceneSeries.slice(0, -1))
  }

  const handleAttempt = (attempt) => {
    if (attempt.type === 'START') {
      setHasStarted(true)
      setCurrentSceneSettings({
        ...currentSceneSettings,
        showQuestion: showQuestionsOnTestMode(),
      })
    }
    dispatchAttempt(attempt)
  }

  useEffect(() => {
    if (scenarioDetail) {
      handleSceneChange(scenarioDetail.startSceneId)
    }
  }, [scenarioDetail])

  useEffect(() => {
    setIsImmersiveRoom(isRoom())
  }, [])

  const store = useMemo(
    () => ({
      scene,
      isEnding,
      setIsEnding,
      isAborted,
      setIsAborted,
      currentSceneSettings,
      setCurrentSceneSettings,
      handleSceneChange,
      attemptState,
      dispatchAttempt: handleAttempt,
      videoLoaded,
      setVideoLoaded,
      previousScene,
      sceneSeries,
      restartScene,
      hasStarted,
      handleEnding,
      isImmersiveRoom,
      setIsImmersiveRoom,
    }),
    [
      scene,
      isEnding,
      isAborted,
      currentSceneSettings,
      videoLoaded,
      sceneSeries,
      hasStarted,
      isImmersiveRoom,
    ]
  )

  return <SceneContext.Provider value={store}>{children}</SceneContext.Provider>
}
export { SceneContext, SceneProvider }

SceneProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
