import React, { useContext } from 'react'
import { ScenarioContext } from '../../../context/scenarioContext'
import { SceneContext } from '../../../context/sceneContext'

const SceneOrbs = () => {
  const { scenarioDetail } = useContext(ScenarioContext)

  const { scene, handleSceneChange } = useContext(SceneContext)

  const connectedScenes = scenarioDetail.scenes
    .find((s) => s.id === scene.id)
    .elements.map((e) => e.linkToId)

  let row = 0
  const cols = 6

  return scenarioDetail.scenes.map((s, index) => {
    if (index % cols === 0) row += 1
    const activeScene = s.id === scene.id
    const connectedScene = connectedScenes.includes(s.id)
    const size = '0.1'

    let color = 'gray'
    let opacity = 0.3
    if (connectedScene) {
      color = 'blue'
      opacity = 0.8
    }
    if (activeScene) {
      color = 'red'
      opacity = 1
    }

    const pos = { x: `${(index % cols) - cols / 2}`, y: '-.5', z: `-${2 + row * 2}` }
    const position = `${pos.x} ${pos.y} ${pos.z} `

    const getActiveSceneInfo = () => {
      return `
      connectedScenes: ${connectedScenes}
      northOffset: ${s.northOffset}
      elements:
      ${s.elements
        .map((e) => {
          return `
            answer: ${e.id}
            linkToScene: ${e.linkToId}
            value: ${e.name}
            isEnding: ${e.linkToEnding}
          `
        })
        .join('')}
  `
    }

    const getConnectedSceneInfo = () => {
      if (!connectedScene) return ''
      return `
      connectedScenes: ${s.elements.map((e) => e.linkToId)}
      northOffset: ${s.northOffset}
      elements:
      ${s.elements
        .map((e) => {
          return `
            answerId: ${e.id}
            linkToScene: ${e.linkToId}
            value: ${e.name}
            isEnding: ${e.linkToEnding}
          `
        })
        .join('')}
  `
    }

    const text = `
    value: id: ${s.id}
    name: ${s.name}
    number: ${s.number}
    ${s.id === scenarioDetail.startSceneId ? '\nisStartScene: true' : ''}
    isActive: ${activeScene}
    isConnected: ${connectedScene}
    ${activeScene && getActiveSceneInfo()}
    ${getConnectedSceneInfo()}
    `

    return (
      <a-sphere
        color={color}
        opacity={opacity}
        key={`orb-${s.id}`}
        position={position}
        radius={size}
      >
        {s.video === null && (
          <>
            <a-cylinder color="red" opacity="0.2" scale="0.02 0.06 0.02" position="0.15 0.11 0" />
            <a-sphere color="red" opacity="0.2" scale="0.02 0.02 0.02" position="0.15 0.05 0" />
          </>
        )}
        <a-text
          value={text}
          scale="0.2 0.2 0.2"
          color="#fff"
          baseline="bottom"
          position=" 0.0 0.2 0"
        >
          <a-text value={text} color="black" baseline="bottom" position=" 0.005 0.005 -0.001" />
        </a-text>
        {activeScene &&
          scene.elements.map((element, i) => {
            const p = `${i * 0.2} -0.3 0`
            return (
              <a-box
                key={`element-${element.id}`}
                data-button
                onClick={() => handleSceneChange(element.linkToId)}
                color="black"
                width="0.15"
                height="0.1"
                depth="0.001"
                position={p}
              >
                <a-text
                  align="center"
                  value={`${element.linkToId}`}
                  scale="0.2 0.2 0.2"
                  position="0 0 0.001"
                />
              </a-box>
            )
          })}
      </a-sphere>
    )
  })
}

export default SceneOrbs
