import React, { useContext, useState } from 'react'
import { FaExpand, FaFileAlt, FaTimes, FaUndo, FaVolumeMute, FaVolumeUp } from 'react-icons/fa'
import { v4 as uuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { closeFullscreen, openFullscreen } from '../../helpers/javascript'
import { ScenarioContext } from '../../context/scenarioContext'
import { SceneContext } from '../../context/sceneContext'
import { UserContext } from '../../context/userContext'
import FeedbackButton from '../FeedbackButton/FeedbackButton'
import TestingControls from '../Scenario/TestingControls'
import HudButton from '../Scenario/HudButton'

const UserHUD = ({ isShowingQuestionFadeIn }) => {
  const { t } = useTranslation()
  const [muted, setMuted] = useState(false)
  const { scenarioDetail } = useContext(ScenarioContext)
  const { dispatchAttempt, isEnding, setIsEnding, setIsAborted, restartScene, hasStarted } =
    useContext(SceneContext)
  const {
    user: [user],
  } = useContext(UserContext)

  const handleStopPlaying = () => {
    if (hasStarted) dispatchAttempt({ type: 'ABORT', uuid: uuidV4() })
    setIsAborted(true)
    setIsEnding(true)
  }

  const handleMute = () => {
    document.querySelector('#video').muted = !muted
    setMuted(!muted)
  }

  const handleFullScreen = () => {
    if (!document.fullscreenElement) openFullscreen(document.documentElement)
    else closeFullscreen()
  }
  if (isEnding) return null

  return (
    <>
      {scenarioDetail.testing && hasStarted && (
        <TestingControls isShowingQuestionFadeIn={isShowingQuestionFadeIn} />
      )}

      <div className="absolute bottom-0 right-0 z-10 w-screen transition duration-500 md:w-auto">
        <div className="w-100 ml-auto mr-12 mb-10 flex flex-shrink space-x-6 font-semibold text-white lg:w-fit lg:rounded-tl">
          <HudButton title={t('stop_playing', 'Stop playing')} onClick={handleStopPlaying}>
            <FaTimes className="text-xl" />
          </HudButton>

          {hasStarted && (
            <HudButton title={t('restart_scene', 'Restart scene')} onClick={restartScene}>
              <FaUndo className="text-xl" />
            </HudButton>
          )}

          <HudButton
            title={`${muted ? t('unmute', 'Unmute') : t('mute', 'Mute')}`}
            onClick={handleMute}
            className={muted ? 'bg-warp-yellow text-black !opacity-100' : 'text-white'}
          >
            <>
              {!muted && <FaVolumeUp className="text-xl" />}
              {muted && <FaVolumeMute className="text-xl" />}
            </>
          </HudButton>

          {scenarioDetail.pdf && (
            <HudButton title={t('open_pdf', 'Open PDF document')}>
              <a
                rel="noreferrer"
                target="_blank"
                label="pdf"
                href={process.env.REACT_APP_IMAGE_URL + scenarioDetail.pdf.url}
              >
                <FaFileAlt className="text-xl" title={t('open_pdf', 'Open PDF document')} />
              </a>
            </HudButton>
          )}

          <HudButton title={t('toggle_fullscreen', 'Toggle fullscreen')} onClick={handleFullScreen}>
            <FaExpand className="text-xl" />
          </HudButton>

          <FeedbackButton email={user.email} />
        </div>
      </div>
    </>
  )
}
export default UserHUD

UserHUD.propTypes = {
  isShowingQuestionFadeIn: PropTypes.func.isRequired,
}
