export const qS = (elem) => document.querySelector(elem)

export const moveCameraToPosition = (x, y, deg = true) => {
  const controls = qS('a-camera').components['look-controls']
  if (deg) {
    controls.pitchObject.rotation.x = window.THREE.MathUtils.degToRad(x)
    controls.yawObject.rotation.y = window.THREE.MathUtils.degToRad(y)
  } else {
    controls.pitchObject.rotation.x = x
    controls.yawObject.rotation.y = y
  }
}

export const getCameraPosition = () => {
  if (!qS('a-camera')) return {}

  const { x, y } = qS('a-camera').getAttribute('rotation')

  return { x, y }
}
export const getRotation = ({ x, y }) => {
  const xOffset = -x || 0
  const yOffset = y || 0
  return `${yOffset} ${xOffset}`
}

export const getShowInLookDirectionRotation = () => {
  const pos = getCameraPosition()
  return getRotation({ x: -pos.y, y: pos.x })
}
