import React, { useContext } from 'react'
import { AuthContext } from '../context/authContext'

const ErrorPage = () => {
  const { removeTokens } = useContext(AuthContext)
  return (
    <div className="grid h-screen place-content-center">
      <div className="flex flex-col space-y-4">
        <h1 className="text-center text-6xl font-extrabold text-warp-gray">404</h1>
        <span className="text-2xl italic text-gray-200">Oops, something went wrong</span>
        <a
          href="/"
          className="mt-10 rounded bg-warp-red px-4 py-2 text-center font-bold text-white shadow-md button hover:bg-warp-red-active"
        >
          Head back home
        </a>
      </div>
      <div className="mt-10">If you keep seeing this page</div>
      <button
        type="button"
        onClick={() => {
          removeTokens()
        }}
        className="mt-10 rounded bg-gray-200 px-4 py-2 text-center font-bold text-warp-gray shadow-md transition-all duration-300 button hover:bg-warp-gray hover:text-gray-600"
      >
        Click here to sign out
      </button>
    </div>
  )
}
export default ErrorPage
