import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import ScenarioModal from '../Scenario/ScenarioModal'
import ScenarioCard from '../Scenario/ScenarioCard'
import { isRoom } from '../../helpers/browser'

// Get image url from env
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const ROOM_COLUMN_WIDTH = 1000
const LIMIT = 12

const ScenariosTab = ({ type, clients, scenarios, roomSlide }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const { scenarioId } = useParams()
  const [selectedScenario, setSelectedScenario] = useState(null)
  const data = scenarios.reduce((array, scenario) => {
    const currentClient = clients.find((client) => scenario.client.id === client.id)
    array.push({
      id: scenario.id,
      name: scenario.name,
      highscore: scenario.highscore,
      scoringKind: scenario.scoringSystem.kind,
      description: scenario.description,
      testing: scenario.testing,
      rating: null,
      image: `${IMAGE_URL}/${scenario.imageUrl}`,
      primaryColor: currentClient.primaryColor || '#e7461a',
      client: { ...currentClient },
      logo: `${IMAGE_URL}/${currentClient.logoMobileUrl}`,
    })
    return array
  }, [])
  const navigate = useNavigate()

  const openModalHandler = (scenario) => {
    navigate(`/${scenario.testing ? 'tests' : 'scenarios'}/${scenario.id}/modal`)
  }

  const closeModalHandler = () => {
    navigate(`/${scenarios.find((s) => s.id === scenarioId).testing ? 'tests' : 'scenarios'}`)
  }

  useEffect(() => {
    if (scenarioId) {
      setSelectedScenario(scenarios.find((s) => s.id === scenarioId))
      setShowModal(true)
    } else {
      setSelectedScenario(null)
    }
  }, [scenarioId])

  if (scenarios.length === 0) {
    return (
      <div className="w-100 mt-20 text-center text-warp-gray">
        <div>{t('no_scenarios_play', "You don't have any scenarios to play.")}</div>
        <div>{t('contact_organiation_access', 'Contact your organisation for access.')}</div>
      </div>
    )
  }

  const renderCards = () => {
    if (!isRoom())
      return (
        <div
          id={type}
          className="relative grid w-full cursor-pointer grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4"
        >
          {data.map((scenario) => (
            <ScenarioCard
              key={scenario.id}
              scenario={scenario}
              onClick={() => openModalHandler(scenario)}
            />
          ))}
        </div>
      )

    const slidesCount = Math.ceil(data.length / LIMIT)
    return (
      <div className="overflow-hidden w-full">
        <div
          id={type}
          className="relative transition-all duration-300 flex"
          style={{
            left: roomSlide * -ROOM_COLUMN_WIDTH,
            width: `${ROOM_COLUMN_WIDTH * slidesCount}px`,
          }}
        >
          {Array.from({ length: slidesCount }, (value, index) => index).map((slide) => (
            <div
              key={slide}
              className="relative grid gap-4 grid-cols-4 grid-rows-3 items-start"
              style={{
                width: `${ROOM_COLUMN_WIDTH}px`,
              }}
            >
              {data
                .filter((d, index) => Math.floor(index / LIMIT) === slide)
                .map((scenario) => (
                  <ScenarioCard
                    key={scenario.id}
                    scenario={scenario}
                    onClick={() => openModalHandler(scenario)}
                  />
                ))}
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <>
      {selectedScenario && (
        <ScenarioModal
          showModal={showModal}
          scenario={selectedScenario}
          onClose={closeModalHandler}
        />
      )}
      {data && renderCards()}
    </>
  )
}

export default ScenariosTab

ScenariosTab.propTypes = {
  type: PropTypes.string.isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  roomSlide: PropTypes.number,
  scenarios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
}
ScenariosTab.defaultProps = {
  clients: [],
  scenarios: [],
  roomSlide: 0,
}
