import React from 'react'
import PropTypes from 'prop-types'

const NorthOffsetLine = ({ northOffset }) => {
  const getRotation = ({ x, y }) => {
    const xOffset = -x || 0
    const yOffset = y || 0
    return `${yOffset} ${xOffset}`
  }

  return (
    <a-entity rotation={`${getRotation({ x: northOffset, y: 0 })} 0`}>
      <a-entity
        id="northOffsetLine"
        position="0 0 -10"
        geometry="primitive: plane; height: 100; width:0.04;"
        material="color: #E7461A; opacity: 0.5"
      />
    </a-entity>
  )
}
export default NorthOffsetLine

NorthOffsetLine.propTypes = {
  northOffset: PropTypes.number.isRequired,
}
