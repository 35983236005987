import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dropdown from '../Dropdown'

const Filter = ({ text, icon, filterHandler, selected, filterOptions, header }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const selectedItem = filterOptions.find((o) => o.value === selected)

  const button = () => (
    <button
      type="button"
      className="flex items-center rounded-[40px] border border-hex-ddd py-2 px-6 mb-1 md:mb-0"
      onClick={() => setShowDropdown(true)}
    >
      <span className="flex items-center text-warp-gray">
        <span className="mr-2">{icon}</span>
        {text}
      </span>
      <span className="text-dark mx-1">{selectedItem?.render ?? selectedItem}</span>
    </button>
  )
  const content = () => {
    const list = [
      <li className="block w-full px-4 py-2 text-left text-xs text-warp-gray" key="all">
        {header || text}
      </li>,
      ...filterOptions.map((option) => (
        <button
          type="button"
          key={`listitem-${option.value ?? option}`}
          data-testid={`listitem-${option.value ?? option}`}
          className={`block w-full px-4 py-2 text-left text-sm transition-all duration-150 hover:bg-warp-red hover:bg-opacity-10 ${
            selected === (option.value ?? option) ? 'text-warp-red-active' : ''
          }`}
          onClick={(e) => {
            e.preventDefault()
            filterHandler(option === 'All' ? '' : option.value ?? option)
          }}
        >
          {option.render ?? option}
        </button>
      )),
    ]
    return list
  }

  return (
    <div className="relative">
      {button()}
      <Dropdown arrow isVisible={showDropdown} toggleVisible={setShowDropdown}>
        <div className="py-2">{content()}</div>
      </Dropdown>
    </div>
  )
}

const listItemType = PropTypes.shape({
  value: PropTypes.string,
  render: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
})

Filter.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  filterHandler: PropTypes.func.isRequired,
  header: PropTypes.string,
  filterOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(listItemType),
  ]).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, listItemType]),
}

Filter.defaultProps = {
  icon: <span />,
  selected: '',
  header: '',
}

export default Filter
