import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { LANGS, changeLanguage } from '../../i18n'
import Dropdown from './Dropdown'

const LanguageMenuItems = ({ isVisible, toggleVisible, position }) => {
  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(false)

  useEffect(() => {
    setSelectedLanguage(LANGS.find((l) => l.key === i18n.language))
  }, [i18n.language])

  return (
    <Dropdown arrow isVisible={isVisible} toggleVisible={toggleVisible} sub position={position}>
      <div className="divide-y divide-hex-eee py-2 ">
        {LANGS.map((lang) => (
          <React.Fragment key={lang.key}>
            {selectedLanguage.key === lang.key && (
              <div className="block w-full px-4 py-2 text-left text-sm text-warp-red transition-all duration-150 hover:bg-warp-red hover:bg-opacity-10 rounded-none">
                {lang.value}
              </div>
            )}
            {selectedLanguage.key !== lang.key && (
              <button
                type="button"
                className={`block w-full px-4 py-2 text-left text-sm transition-all duration-150 hover:bg-warp-red hover:bg-opacity-10 rounded-none ${
                  selectedLanguage.key === lang.key ? 'text-warp-red' : ''
                }`}
                onClick={() => {
                  changeLanguage(lang.key)
                }}
              >
                {lang.value}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    </Dropdown>
  )
}

LanguageMenuItems.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleVisible: PropTypes.func.isRequired,
  position: PropTypes.string,
}

LanguageMenuItems.defaultProps = {
  position: 'bottom',
}

export default LanguageMenuItems
