import React, { createContext, useContext, useMemo, useState } from 'react'
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { AuthContext } from './authContext'
import appsignal from '../helpers/appsignal'

const UserContext = createContext()

const CREATE_TOKENS_FROM_CODE = gql`
  mutation createToken($grant: TokenGrant!, $device: DeviceInput!, $code: String!) {
    createToken(grant: $grant, device: $device, code: $code) {
      accessToken
      refreshToken
    }
  }
`

const CREATE_TOKENS_FROM_SESSION = gql`
  mutation createTokenFromSession($grant: TokenGrant!, $device: DeviceInput!) {
    createToken(grant: $grant, device: $device) {
      accessToken
      refreshToken
    }
  }
`

const GET_CURRENT_SESSION = gql`
  query currentSession {
    currentSession {
      id
      launchCode
      lmsName
      lmsType
      scenario {
        id
      }
      scenarios {
        id
        testing
      }
      courses {
        id
      }
      sessionType
      state
      verificationCode
    }
  }
`

const GET_USER = gql`
  query currentUser {
    currentUser {
      id
      name
      firstName
      lastName
      email
      activated
      administrator
      restricted
      hasAcceptedTermsOfUse
      avatar {
        medium
      }
    }
  }
`
const decorator = (tags) => {
  return (span) => span.setTags(tags)
}

const UserProvider = ({ children }) => {
  const {
    setTokens,
    removeTokens,
    getDevice,
    isAuthenticated: [, setIsAuthenticated],
    sessionType: [sessionType, setSessionType],
    lmsName: [, setLmsName],
  } = useContext(AuthContext)

  const [code, setCode] = useState('')
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
  })
  // TODO: report failures to appsignal
  const [loginFailure, setLoginFailure] = useState(false)

  const [lmsScenarioId, setLmsScenarioId] = useState(window.sessionStorage.getItem('scenarioId'))

  useQuery(GET_USER, {
    onError: () => {
      // throw new Error(error.message)
      setLoginFailure(true)
    },
    onCompleted: ({ currentUser }) => {
      if (currentUser !== null) {
        setUser({ ...currentUser })
        const tags = {
          user_id: currentUser.id,
        }
        appsignal.addDecorator(decorator(tags))
      }
    },
  })
  // GRAPHQL
  const [createTokensFromSession] = useMutation(CREATE_TOKENS_FROM_SESSION, {
    onCompleted: async (data) => {
      const d = data
      const { accessToken, refreshToken } = d.createToken
      removeTokens('ANONYMOUS')
      setTokens(accessToken, refreshToken, sessionType)
      setIsAuthenticated(true)
      return data
    },
    onError: () => {
      setLoginFailure(true)
    },
  })

  const [getCurrentSessionQuery] = useLazyQuery(GET_CURRENT_SESSION, {
    fetchPolicy: 'network-only',
  })
  const getCurrentSession = () =>
    new Promise((resolve, reject) => {
      getCurrentSessionQuery({
        onError: () => {
          setLoginFailure(true)
          reject(new Error('something bad happened'))
        },
        onCompleted: async (data) => {
          const st = data.currentSession?.sessionType
          if (st) {
            setSessionType(st)

            if (st === 'LMS') {
              setIsAuthenticated(true)
              setSessionType(st)
              setLmsName(data.currentSession.lmsName)
              const scenarioId = data.currentSession.scenario.id
              window.sessionStorage.setItem('scenarioId', scenarioId)
              setLmsScenarioId(scenarioId)
              resolve(data.currentSession)
            }

            if (st === 'LOGIN') {
              await createTokensFromSession({
                variables: {
                  grant: 'SESSION',
                  device: getDevice(st),
                },
              })
              resolve(data.currentSession)
            }

            if (st === 'ANONYMOUS') {
              setIsAuthenticated(true)
              resolve(data.currentSession)
            }

            // invalid session type
            setLoginFailure(true)
            reject(new Error('Invalid session type'))
          }
          reject(new Error('something bad happened'))
          return false
        },
      })
    })

  const [createTokensFromCode] = useMutation(CREATE_TOKENS_FROM_CODE, {
    variables: {
      grant: 'CODE',
      device: getDevice('ANONYMOUS'),
      code,
    },
    onError: (error) => {
      console.log(JSON.stringify(error))
      setLoginFailure(true)
    },
  })

  const login = async (navigate) => {
    setLoginFailure(false)

    // There was a weird bug in production when we used return a promise with await inside it
    const {
      data: { createToken },
    } = await createTokensFromCode()

    // set temporary token into session storage
    const tokens = createToken
    setTokens(tokens.accessToken, tokens.refreshToken, 'ANONYMOUS')
    return getCurrentSession().then((currentSession) => {
      if (currentSession) {
        const connectedCourses = currentSession.courses
        const connectedScenarios = currentSession.scenarios
        if (connectedCourses?.length === 1) {
          navigate(`/courses/${connectedCourses[0].id}`)
        } else if (connectedScenarios?.length === 1) {
          const connectedScenario = connectedScenarios[0]
          navigate(
            `/${connectedScenario.testing ? 'tests' : 'scenarios'}/${connectedScenario.id}/modal`
          )
        }
      }
      return currentSession
    })
  }

  const logout = () => {
    removeTokens(sessionType)
    setLoginFailure(false)
    setIsAuthenticated(false)
  }

  const store = useMemo(
    () => ({
      user: [user, setUser],
      code: [code, setCode],
      loginFailure: [loginFailure, setLoginFailure],
      login,
      logout,
      lmsScenarioId,
    }),
    [user, code, loginFailure, lmsScenarioId]
  )

  return <UserContext.Provider value={store}>{children}</UserContext.Provider>
}

export { UserContext, UserProvider }

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
