const calculateScenarioScore = (scenarioDetail, chosenAnswers) => {
  const pointsArray = scenarioDetail.scenes.reduce((sceneArray, { elements }) => {
    elements.forEach((element) => {
      sceneArray.push({ answerId: element.id, points: element.points })
    })
    return sceneArray
  }, [])
  const score = chosenAnswers.reduce((sum, currentValue) => {
    const p = pointsArray.find((item) => item.answerId === currentValue).points
    const newSum = sum + p
    return newSum
  }, 0)

  return score
}
export default calculateScenarioScore
