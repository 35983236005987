import React, { useContext } from 'react'
import { SceneContext } from '../../../context/sceneContext'

const Nadir = () => {
  const { scene } = useContext(SceneContext)

  const getRotation = () => {
    if (scene.northOffsetEnabled) return `-90 -${Number(scene.northOffset)} 0`
    return '-90 0 0'
  }
  return (
    <a-entity position="0 -5.2 0">
      <a-circle segments="180" src="#client-vr-logo" rotation={getRotation()} />
    </a-entity>
  )
}
export default Nadir
