import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../context/authContext'
import { UserContext } from '../context/userContext'

const NameBanner = () => {
  const { t } = useTranslation()

  const {
    user: [user],
  } = useContext(UserContext)

  const {
    sessionType: [sessionType],
  } = useContext(AuthContext)

  return (
    <div className="flex-1 py-3 xl:py-12 room:hidden" data-testid="namebanner">
      <h1 className="warp-red text-2xl font-bold lg:text-3xl xl:text-4xl">
        {t('hi', 'Hi')} {sessionType === 'ANONYMOUS' ? 'there' : user.firstName}
      </h1>
    </div>
  )
}
export default NameBanner
