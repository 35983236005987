const browser = {
  isOpera: () => navigator.userAgent.match(/Opera/i) || navigator.userAgent.match(/OPR/i),
  isEdge: () => navigator.userAgent.match(/Edg/i),
  isChrome: () => navigator.userAgent.match(/Chrome/i),
  isSafari: () => navigator.userAgent.match(/Safari/i),
  isFirefox: () => navigator.userAgent.match(/Firefox/i),
}

const isBrowserSupported = () => !!browser.isChrome() || !!browser.isFirefox() || !!browser.isEdge()

const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

const isRoom = () => {
  const { innerWidth: width, innerHeight: height } = window
  const cubemapPrecision = Math.abs(width / height - 6.0)
  return width >= 6000 && height >= 1000 && cubemapPrecision <= 0.1
}

export { browser, isMobile, isBrowserSupported, isRoom }
