import React, { useContext } from 'react'
import { v4 as uuidV4 } from 'uuid'
import PropTypes from 'prop-types'
import { getRotation, getShowInLookDirectionRotation } from '../helpers/controls'
import TextBubble from './partials/TextBubble'
import Button from './partials/Button'
import { SceneContext } from '../../../context/sceneContext'
import { ScenarioContext } from '../../../context/scenarioContext'

const Information = ({ element, childElements }) => {
  const { colors } = useContext(ScenarioContext)
  const { scene, currentSceneSettings, handleSceneChange, dispatchAttempt, handleEnding } =
    useContext(SceneContext)

  const buttonEl = childElements[0]

  let WIDTH = 2.6
  if (element.labelSize === 'WIDE') WIDTH *= 2

  const handleRotation = () => {
    if (element.showInLookDirection) return `${getShowInLookDirectionRotation()} 0`
    return `${getRotation({ x: element.anchorX, y: element.anchorY })} 0`
  }

  return (
    <a-entity
      key={uuidV4()}
      id="information"
      hideable-element={`hidden: ${!currentSceneSettings.showQuestion}`}
      rotation={handleRotation()}
    >
      <a-entity position="0 0 -7">
        <TextBubble
          textValue={element.label || 'Information'}
          font={element.labelFont}
          width={WIDTH}
          id={element.id}
        />
        {buttonEl && (
          <Button
            clickHandler={() => {
              dispatchAttempt({
                type: 'CHOICE',
                value: { sceneId: scene.id, answerId: buttonEl.id },
                uuid: uuidV4(),
              })
              if (buttonEl.linkToEnding) return handleEnding()
              return handleSceneChange(buttonEl.linkToId)
            }}
            textColor={colors.text}
            font={element.labelFont}
            buttonColor={colors.primaryColor}
            textValue={buttonEl.label || 'Button'}
            width={WIDTH}
            id="button"
          />
        )}
      </a-entity>
    </a-entity>
  )
}

export default Information

Information.propTypes = {
  element: PropTypes.shape.isRequired,
  childElements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}
