import React from 'react'
import PropTypes from 'prop-types'
import { getFontUrl } from '../../../../helpers/fonts'

const Button = ({ textValue, font, clickHandler, buttonColor, textColor, width, id, type }) => {
  return (
    <a-entity id={id} button={`type:${type}; color: ${buttonColor}; width: ${width}`}>
      <a-text
        id={`${id}-text-button`}
        font={getFontUrl(font)}
        negate="false"
        shader="msdf"
        value={textValue}
        color={textColor}
        anchor="center"
        align="center"
        material="shader:flat;visible: false;"
        geometry="primitive:plane;width:auto;height:auto"
        position="0 0.018 0"
        wrap-count={width > 1.8 ? width * 8.4 : 14}
        letter-spacing={-1.5}
        width={width - 0.2}
      />
      <a-entity id="button" data-button onClick={clickHandler} />
    </a-entity>
  )
}

export default Button

Button.propTypes = {
  buttonColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  textValue: PropTypes.string.isRequired,
  font: PropTypes.string.isRequired,
  width: PropTypes.number,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
}
Button.defaultProps = {
  width: 1.4,
  type: 'default',
}
