import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { SceneContext } from '../../context/sceneContext'
import ActiveSceneInformation from '../aframe/DevTools/HUD/ActiveSceneInformation'
import VideoInformation from '../aframe/DevTools/HUD/VideoInformation'
import StartScreen from '../aframe/StartScreen'
import RoomStartScreen from '../aframe/RoomStartScreen'

const Camera = ({
  showDevTools,
  showSceneOrbs,
  setShowSceneOrbs,
  showNorthOffsetLine,
  setShowNorthOffsetLine,
  selectedQuality,
  setSelectedQuality,
}) => {
  const { hasStarted, isImmersiveRoom, setIsImmersiveRoom } = useContext(SceneContext)

  const getDevToolHUD = () => (
    <>
      <ActiveSceneInformation
        showSceneOrbs={showSceneOrbs}
        setShowSceneOrbs={setShowSceneOrbs}
        showNorthOffsetLine={showNorthOffsetLine}
        setShowNorthOffsetLine={setShowNorthOffsetLine}
        isImmersiveRoom={isImmersiveRoom}
        setIsImmersiveRoom={setIsImmersiveRoom}
      />
      <VideoInformation selectedQuality={selectedQuality} setSelectedQuality={setSelectedQuality} />
    </>
  )

  return (
    <>
      {/* Text element to show a message until scene starts playing */}
      {!hasStarted && (isImmersiveRoom ? <RoomStartScreen /> : <StartScreen />)}
      {/* HUD Elements */}
      {showDevTools && getDevToolHUD()}
    </>
  )
}

export default Camera

Camera.propTypes = {
  showDevTools: PropTypes.bool.isRequired,
  showSceneOrbs: PropTypes.bool.isRequired,
  setShowSceneOrbs: PropTypes.func.isRequired,
  showNorthOffsetLine: PropTypes.bool.isRequired,
  setShowNorthOffsetLine: PropTypes.func.isRequired,
  selectedQuality: PropTypes.string.isRequired,
  setSelectedQuality: PropTypes.func.isRequired,
}
