import React, { useContext } from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { UserContext } from '../../context/userContext'

const LIMIT = {
  courses: 2,
  scenarios: 12,
}

const DashboardRoomNavs = ({ onClick, navState, activeTab }) => {
  const { t } = useTranslation()
  const { logout } = useContext(UserContext)

  return (
    <div className="flex">
      {navState[activeTab].slide > 0 && (
        <button
          label="back"
          onClick={() => onClick('left')}
          type="button"
          className="rounded-[40px] py-3 px-6 font-semibold bg-warp-gray text-white mr-1"
        >
          <FaArrowLeft />
        </button>
      )}
      {navState[activeTab].slide + 1 < Math.floor(navState[activeTab].count / LIMIT[activeTab]) && (
        <button
          label="back"
          onClick={() => onClick('right')}
          type="button"
          className="rounded-[40px] py-3 px-6 font-semibold bg-warp-gray text-white mr-1"
        >
          <FaArrowRight />
        </button>
      )}
      <button
        label="back"
        onClick={logout}
        type="button"
        className="py-3 font-semibold text-warp-gray ml-2"
      >
        {t('sign_out_button', 'Sign out')}
      </button>
    </div>
  )
}

DashboardRoomNavs.propTypes = {
  onClick: PropTypes.func.isRequired,
  navState: PropTypes.shape().isRequired,
  activeTab: PropTypes.string.isRequired,
}

export default DashboardRoomNavs
