import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import Modal from '../Modal'
import { UserContext } from '../../context/userContext'

const UPDATE_PROFILE = gql`
  mutation updateProfile($firstName: String!, $lastName: String!) {
    updateProfile(firstName: $firstName, lastName: $lastName) {
      currentUser {
        firstName
        lastName
      }
    }
  }
`

const EditProfile = ({ show, onClose }) => {
  const { t } = useTranslation()

  const {
    user: [user, setUser],
  } = useContext(UserContext)

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
  })

  const setDefaultValues = () => {
    setFormValues({
      firstName: user.firstName,
      lastName: user.lastName,
    })
  }

  const handleClose = () => {
    setDefaultValues()
    onClose()
  }

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted: ({ updateProfile: { currentUser } }) => {
      onClose()
      setUser({
        ...user,
        ...currentUser,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
      })
    },
  })
  useEffect(() => {
    if (user) setDefaultValues()
  }, [user])

  if (!user) return null

  const handleChange = ({ target }) => {
    setFormValues({ ...formValues, [target.name]: target.value })
  }

  const handleSubmit = () => {
    updateProfile({ variables: formValues })
  }

  return (
    <Modal
      show={show}
      maxWidth="3xl"
      onClose={handleClose}
      headerText={t('edit_profile', 'Edit profile')}
      closeButton
      useDefaultButtons
      submitHandler={handleSubmit}
      submitDisabled={!formValues.firstName || !formValues.lastName}
    >
      <form className="flex w-full flex-col">
        <label htmlFor="firstName" className="mb-5 flex flex-col">
          <span className="mb-2 text-sm font-bold">{t('firstname', 'Firstname')}</span>
          <input
            id="firstName"
            type="text"
            required
            name="firstName"
            onChange={handleChange}
            value={formValues.firstName}
          />
        </label>
        <label htmlFor="lastname" className="mb-5 flex flex-col">
          <span className="mb-2 text-sm font-bold">{t('lastname', 'Lastname')}</span>
          <input
            id="lastName"
            type="text"
            required
            name="lastName"
            onChange={handleChange}
            value={formValues.lastName}
          />
        </label>
      </form>
    </Modal>
  )
}

export default EditProfile

EditProfile.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

EditProfile.defaultProps = {
  onClose: () => {},
}
