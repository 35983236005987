import React, { useContext } from 'react'
import { SceneContext } from '../../context/sceneContext'
import Direction from '../aframe/objects/Direction'
import Hotspot from '../aframe/objects/Hotspot'
import Information from '../aframe/objects/Information'
import MultipleChoice from '../aframe/objects/MultipleChoice'
import Tooltip from '../aframe/objects/Tooltip'
import Button from '../aframe/objects/Button'
import elementHelper from '../../helpers/element'

const Elements = () => {
  const { scene } = useContext(SceneContext)

  const getElements = () => {
    const allAnswerElements = scene.elements.filter(
      (el) => elementHelper.GROUP_CHILD_ELEMENT_TYPES.includes(el.kind) && el.groupUuid
    )

    const elements = scene.elements.filter(
      (el) => !allAnswerElements.find((aEl) => aEl.id === el.id)
    )

    const renderElement = (el) => {
      const kind = el.groupKind || el.kind
      const id = `${el.kind}-${el.id}`
      const answerElements = allAnswerElements.filter((a) => a.groupUuid === el.groupUuid)

      switch (kind) {
        case 'INFORMATION':
          return <Information key={id} id={id} element={el} childElements={answerElements} />
        case 'HOTSPOT':
          return <Hotspot key={id} id={id} element={el} />
        case 'DIRECTION':
          return <Direction key={id} id={id} element={el} />
        case 'MPC':
          return <MultipleChoice key={id} id={id} element={el} childElements={answerElements} />
        case 'TOOLTIP':
          return <Tooltip key={id} id={id} element={el} />
        case 'BUTTON':
          return <Button key={id} id={id} element={el} />
        default:
          return null
      }
    }
    return elements.map((el) => renderElement(el))
  }
  return (
    <>
      {/* Place elements here */}
      {getElements()}
    </>
  )
}

export default Elements
