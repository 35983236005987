const hexToRGB = (hex) => {
  let r = 0
  let g = 0
  let b = 0

  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`
    g = `0x${hex[2]}${hex[2]}`
    b = `0x${hex[3]}${hex[3]}`
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`
    g = `0x${hex[3]}${hex[4]}`
    b = `0x${hex[5]}${hex[6]}`
  } else return null

  return {
    red: parseInt(r, 16),
    green: parseInt(g, 16),
    blue: parseInt(b, 16),
  }
}

const getLuminance = (color) => {
  const { red, green, blue } = hexToRGB(color)

  // Multiplier is a magic number :)
  const contrastValues = {
    red: red ** 2 * 0.299,
    green: green ** 2 * 0.587,
    blue: blue ** 2 * 0.114,
  }

  return Math.floor(Math.sqrt(contrastValues.red + contrastValues.green + contrastValues.blue))
}

const isBrightColor = (color) => getLuminance(color) > 186

export { getLuminance, isBrightColor }
