import React, { useContext } from 'react'
import { v4 as uuidV4 } from 'uuid'
import PropTypes from 'prop-types'
import { SceneContext } from '../../../context/sceneContext'
import { ScenarioContext } from '../../../context/scenarioContext'
import { getRotation } from '../helpers/controls'
import TextBubble from './partials/TextBubble'

const Direction = ({ element }) => {
  const { colors } = useContext(ScenarioContext)
  const {
    scene,
    currentSceneSettings,
    dispatchAttempt,
    handleSceneChange,
    isImmersiveRoom,
    handleEnding,
  } = useContext(SceneContext)

  const getArrowYRotation = () => {
    return isImmersiveRoom ? -17 : -30
  }

  const getDirectionArrow = () => {
    const rotation = getRotation({ x: element.anchorX, y: getArrowYRotation() })
    let labelWidth = 1.8
    if (element.labelSize === 'WIDE') labelWidth *= 2

    return (
      <a-entity
        hideable-element={`hidden: ${!currentSceneSettings.showQuestion}`}
        key={element.id}
        rotation={`${rotation} 0`}
      >
        <a-entity position="0 0 -7">
          {element.showLabel && (
            <TextBubble
              textValue={element.label || 'Direction'}
              font={element.labelFont}
              id={`L${element.id}`}
              width={labelWidth}
            />
          )}
          <a-image
            data-button
            onClick={() => {
              dispatchAttempt({
                type: 'CHOICE',
                value: { sceneId: scene.id, answerId: element.id },
                uuid: uuidV4(),
              })
              if (element.linkToEnding) return handleEnding()
              return handleSceneChange(element.linkToId)
            }}
            color={colors.primaryColor}
            position="0 -0.5 0.01"
            src="#direction-arrow"
          />
        </a-entity>
      </a-entity>
    )
  }

  return (
    <a-entity id="direction" key={uuidV4()}>
      {getDirectionArrow()}
    </a-entity>
  )
}
export default Direction

Direction.propTypes = {
  element: PropTypes.shape.isRequired,
}
