import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FaTrophy } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Stars from '../components/UI/Stars'
import ScenarioPlayDevices from '../components/Scenario/ScenarioPlayDevices/ScenarioPlayDevices'
import { AuthContext } from '../context/authContext'
import { ScenarioContext } from '../context/scenarioContext'
import useScenarioLicenseCheck from '../helpers/hooks/useScenarioLicenseCheck'
import AboutOrganizationModal from '../components/Client/AboutOrganizationModal'

const Scenario = ({ id, course }) => {
  const { t } = useTranslation()
  const {
    sessionType: [sessionType],
  } = useContext(AuthContext)
  const { scenarioId } = useParams()
  const { colors, scenarioDetail: data, getScenarioDetail } = useContext(ScenarioContext)
  const currentScenarioId = id || scenarioId
  const { licenseResult, licenseStatus } = useScenarioLicenseCheck({
    scenarioId: currentScenarioId,
  })
  const [showOrgModal, setShowOrgModal] = useState(false)

  useEffect(() => {
    getScenarioDetail({
      variables: {
        id: currentScenarioId,
      },
    })
  }, [id, scenarioId])

  if (!data) return t('loading', 'Loading')

  const renderTestingLabel = () => {
    if (!data.testing) return null
    return (
      <div className="mb-7">
        <span className="inline-block rounded-3xl bg-warp-yellow px-6 py-3 font-bold text-black">
          Test
        </span>
      </div>
    )
  }

  const renderBuyingClient = () => {
    let buyingClient
    if (course && course.buyingClient?.id !== course.client.id) buyingClient = course.buyingClient
    else buyingClient = data.buyingClient?.id !== data.client.id ? data.buyingClient : null

    if (!buyingClient) return null
    return (
      <div className="mb-9">
        <span className="rounded-3xl bg-white px-6 py-3 font-semibold text-black">
          {t('provided_by', 'Provided by').replace('{0}', buyingClient.appTitle)}
        </span>
      </div>
    )
  }

  const modalMode = !!id
  const lmsMode = sessionType === 'LMS'
  const anonymousMode = sessionType === 'ANONYMOUS'

  const checkLicense = () => {
    return (
      <>
        {licenseStatus && licenseStatus !== 'MISSING' && (
          <ScenarioPlayDevices anonymousMode={anonymousMode} course={course} />
        )}
        {licenseStatus === 'MISSING' && licenseResult === 'FAILED' && (
          <p className="rounded bg-warp-yellow px-7 py-5 text-left text-black">
            {t(
              'scenario_license_error_msg',
              'Unfortunately at this moment there aren’t enough licenses available to play this course. Contact your training manager for assistance.'
            )}
          </p>
        )}
      </>
    )
  }

  return (
    <>
      <div
        className={`bg-black text-white ${!modalMode && !lmsMode && 'lg:p-10'} ${
          lmsMode && 'md:px-4'
        }`}
      >
        <div
          id="image-container"
          className={`relative ${lmsMode ? 'aspect-[2.6/1]' : 'aspect-[2/1]'} h-auto`}
          style={{
            backgroundImage: `url("${process.env.REACT_APP_IMAGE_URL}${data.imageUrl}")`,
            backgroundSize: 'cover',
          }}
        >
          <div
            id="image-shader"
            className="absolute flex h-full w-full items-end bg-gradient-to-t from-black via-transparent to-transparent px-[20px] pt-[20px] lg:px-[60px] lg:pt-[60px]"
          >
            <div className="w-full">
              <div className={`${lmsMode && 'mx-auto max-w-7xl px-[20px] lg:px-[60px]'}`}>
                {renderTestingLabel()}
                {renderBuyingClient()}
                <button
                  className="flex items-center"
                  type="button"
                  onClick={() => setShowOrgModal(true)}
                >
                  <img
                    className="h-14 w-14 rounded-50 bg-white p-2 shadow-lg"
                    src={process.env.REACT_APP_IMAGE_URL + data.client.logoMobileUrl}
                    alt="logo client"
                  />
                  <h3 className="ml-4 text-gray-400">{data.client.appTitle}</h3>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`relative flex flex-col gap-6 p-[20px] lg:flex-row lg:gap-20 lg:p-[60px] lg:pt-9 ${
            lmsMode && 'mx-auto max-w-7xl'
          }`}
        >
          <div className="flex-grow">
            <h1 className="mb-2 text-3xl font-bold">{data.name}</h1>
            {data.scoringSystem.kind !== 'DISABLED' && (
              <span className="row mb-6 flex items-center gap-2 font-bold text-gray-500">
                <Stars value={data.highscore || 0} color={colors.secondaryColor} />
                {data.highscore && (
                  <>
                    <FaTrophy color="gold" />
                    {t('highscore', 'Highscore')}
                  </>
                )}
              </span>
            )}

            <span className="whitespace-pre-line text-warp-gray">{data.description}</span>
          </div>

          <div className="flex flex-col items-start text-center lg:w-[360px] lg:min-w-[360px]">
            {checkLicense()}
          </div>
        </div>
      </div>

      {showOrgModal && (
        <AboutOrganizationModal
          showModal={showOrgModal}
          client={data.client}
          onClose={() => {
            setShowOrgModal(false)
          }}
        />
      )}
    </>
  )
}
export default Scenario

Scenario.propTypes = {
  id: PropTypes.string,
  course: PropTypes.shape(),
}
Scenario.defaultProps = {
  id: null,
  course: null,
}
