import { useEffect } from 'react'

const useRefreshBlocker = (when) => {
  useEffect(() => {
    return () => {
      window.onbeforeunload = () => {}
    }
  }, [])

  useEffect(() => {
    if (when) window.onbeforeunload = () => true
    else window.onbeforeunload = () => {}
  }, [when])
}

export default useRefreshBlocker
