import { roundedRect } from '../../helpers/shapes'

const { AFRAME, THREE } = window

AFRAME.registerComponent('button', {
  schema: {
    color: { default: '#e746a2' },
    type: { default: 'default' },
    width: { default: 1.8 },
    height: { default: 0.4 },
    radius: { default: 0.05 },
    enabled: { default: true },
    paddingY: { default: 0.2 },
  },
  drawButton() {
    const shape = new THREE.Shape()
    const { width, height, radius } = this.data
    roundedRect(shape, -width / 2, -height / 2, width, height, radius || 0.05)
    return new THREE.ShapeGeometry(shape)
  },

  getTextBubble() {
    // Get the elements
    const textBox = this.el.children[0]
    const buttonBox = this.el.children[1]
    if (!textBox || !buttonBox) return null

    const { height } = textBox.getAttribute('geometry')
    if (!height) return null

    this.data.height = height + this.data.paddingY * 2

    this.el.setAttribute('position', `0 ${-this.data.height / 2} 0`)
    textBox.setAttribute('position', `0 0.05 0.03`)

    this.button = new THREE.Mesh(this.drawButton(), this.material)
    this.button.position.set(0, 0, -0.01)
    this.el.setAttribute('height', this.data.height)
    this.el.emit('button-loaded')
    return buttonBox.setObject3D('button', this.button)
  },
  loaded() {
    const child = this.el.children[0]
    child.addEventListener('componentchanged', (e) => {
      if (e.detail.name === 'geometry') {
        const geometry = child.getAttribute('geometry')
        if (geometry.width && geometry.height) {
          this.getTextBubble()
        }
      }
      if (e.detail.name === 'text') {
        setTimeout(() => this.update(), 0)
        this.self.children[0].setAttribute(
          'geometry',
          `primitive: plane; width: auto; height: auto;`
        )
        setTimeout(() => this.update(), 0)
      }
    })
  },
  init() {
    this.material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(this.data.color),
      side: THREE.FrontSide,
    })
    this.el.addEventListener('loaded', () => this.loaded())
  },
})
