import React from 'react'
import logo from '../assets/images/warp-logo.png'

const WarpLogo = () => (
  <a href="/">
    <div id="logo-warp" className="flex items-center">
      <img src={logo} alt="" className="mr-2 h-16 w-16" />
      <span className="mr-1 text-2xl font-bold">Warp VR</span>
    </div>
  </a>
)
export default WarpLogo
