import React, { useContext } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { SceneContext } from '../../context/sceneContext'
import { getFontUrl } from '../../helpers/fonts'

const RoomHUD = () => {
  const { t } = useTranslation()
  const { dispatchAttempt, isEnding, setIsEnding, setIsAborted, restartScene, hasStarted } =
    useContext(SceneContext)

  const handleStopPlaying = () => {
    if (hasStarted) dispatchAttempt({ type: 'ABORT', uuid: uuidV4() })
    setIsAborted(true)
    setIsEnding(true)
  }

  if (isEnding) return null
  if (!hasStarted) return null

  return (
    <a-entity position="0 -3 0">
      <a-entity position="-1.5 -0.9 -6" rotation="-45 0 0">
        <a-entity scale="1 1 1" id="stop-button" button="color: #666666;">
          <a-text
            id="stopbutton-text-button"
            font={getFontUrl('LATIN')}
            negate="false"
            shader="msdf"
            value={t('stop_playing', 'Stop playing')}
            color="white"
            anchor="center"
            align="center"
            material="shader:flat;visible: false"
            geometry="primitive:plane;width:auto;height:auto"
            position="0 0.018 0"
            wrap-count="15"
            width="1.6"
            letter-spacing={-2}
          />
          <a-entity id="button" data-button onClick={handleStopPlaying} />
        </a-entity>
      </a-entity>

      <a-entity position="1.5 -0.9 -6" rotation="-45 0 0">
        <a-entity scale="1 1 1" id="restart-button" button="color: #666666;">
          <a-text
            id="restartbutton-text-button"
            font={getFontUrl('LATIN')}
            negate="false"
            shader="msdf"
            value={t('restart_scene', 'Restart scene')}
            color="white"
            anchor="center"
            align="center"
            material="shader:flat;visible: false;"
            geometry="primitive:plane;width:auto;height:auto"
            position="0 0.018 0"
            wrap-count="15"
            width="1.6"
            letter-spacing={-2}
          />
          <a-entity id="button" data-button onClick={restartScene} />
        </a-entity>
      </a-entity>
    </a-entity>
  )
}
export default RoomHUD
