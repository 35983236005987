import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../UI/Tooltip/Tooltip'

const HudButton = ({ title, onClick, className, children, tooltipPosition, isCircle }) => {
  const getIinner = () => (
    <button
      onClick={onClick}
      type="button"
      className={`grid h-12 w-12 place-content-center bg-black opacity-70 hover:cursor-pointer hover:transition-all ${
        isCircle ? 'rounded-50' : ''
      } ${className}`}
    >
      {children}
    </button>
  )
  if (!title) return getIinner()
  return (
    <Tooltip position={tooltipPosition} title={title}>
      {getIinner()}
    </Tooltip>
  )
}

HudButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  tooltipPosition: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  isCircle: PropTypes.bool,
}
HudButton.defaultProps = {
  title: '',
  className: '',
  tooltipPosition: 'top',
  isCircle: true,
  onClick: () => {},
}

export default HudButton
