const baseUrl = 'https://assets.warpvr.com/aframe-language-fonts'
export const fontSet = {
  LATIN: `${baseUrl}/latin/basic-supplement/Montserrat-Medium.json`,
  CYRILLIC: `${baseUrl}/cyrillic/Roboto-Medium.json`,
  THAI: `${baseUrl}/thai/IBMPlexSansThaiLooped-Medium.json`,
  JAPANESE: `${baseUrl}/japanese/MPLUS1p-Medium.json`,
  FALLBACK: `${baseUrl}/latin/basic-supplement/Montserrat-Medium.json`,
}

export const getFontUrl = (script) => {
  return fontSet[script]
}
