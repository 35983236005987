import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaChevronDown, FaCog } from 'react-icons/fa'
import bannerImage from '../assets/images/warp-bg-lg.png'
import bannerImageSmall from '../assets/images/warp-bg-sm.png'
import WarpLogo from '../components/WarpLogo'
import LanguageMenuItems from '../components/UI/LanguageMenuItems'

const LoginLayout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false)

  const getLanguagePicker = () => (
    <div className="absolute right-6 top-6">
      <button
        type="button"
        label="menu"
        className={`${
          showMenu ? 'border border-black text-black shadow-md' : 'border text-warp-gray'
        } flex h-12 items-center justify-center rounded px-4 transition-all duration-200 hover:text-black hover:shadow-md`}
        onClick={() => setShowMenu(!showMenu)}
      >
        <FaCog className="mr-2" />
        <FaChevronDown />
      </button>
      <LanguageMenuItems isVisible={showMenu} toggleVisible={setShowMenu} />
    </div>
  )

  return (
    <div className="flex flex-col lg:h-screen lg:flex-row lg:items-center  ">
      <div className="flex max-h-[400px] flex-shrink lg:order-1 lg:h-screen lg:max-h-screen lg:w-1/2 room:hidden">
        <img
          className="invisible h-0 w-0 object-cover md:visible lg:h-auto lg:w-full lg:min-w-[400px]"
          src={bannerImage}
          alt=""
          loading="lazy"
        />
        <img
          className="visible h-auto w-auto object-cover lg:invisible lg:h-0 lg:w-0"
          src={bannerImageSmall}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="relative mx-auto flex  h-full items-center px-4 pt-4 lg:flex-1 lg:pt-0">
        {getLanguagePicker()}
        <div className="flex w-full items-center">
          <div
            id="login"
            className="mx-auto flex w-full max-w-full lg:max-w-[414px] flex-col items-center"
          >
            <WarpLogo />
            {children}
            <p className="mt-10 mb-6 text-sm text-gray-400 md:mt-20">Warp VR © 2023</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LoginLayout

LoginLayout.propTypes = {
  children: PropTypes.element.isRequired,
}
