import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const FilterEmptyState = ({ title, icon, clickHandler }) => {
  const { t } = useTranslation()
  return (
    <div className="w-full h-full flex items-center justify-center mt-10">
      <div className="flex flex-col items-center">
        <span className="text-center">{icon}</span>
        <h2 className="text-bold text-black text-center mt-8">{title}</h2>
        <button
          type="button"
          className="border rounded-[40px] border-hex-ddd py-2.5 px-5 mt-8 text-warp-gray font-semibold"
          onClick={clickHandler}
        >
          {t('clear_filters', 'Clear all filters')}
        </button>
      </div>
    </div>
  )
}

export default FilterEmptyState

FilterEmptyState.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
}

FilterEmptyState.defaultProps = {
  icon: <div className="w-[200px] h-[200px] rounded-50 border-[50px] border-hex-eee" />,
}
