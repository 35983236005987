import React from 'react'
import PropTypes from 'prop-types'
import Card from '../UI/Card/Card'
import ClientLogo from '../UI/ClientLogo'
import Stars from '../UI/Stars'

const ScenarioCard = ({ scenario, onClick }) => {
  return (
    <Card
      id={`card-${scenario.id}`}
      key={scenario.id}
      backgroundImage={scenario.image}
      onClick={onClick}
    >
      <div className="flex items-end">
        <ClientLogo src={scenario.logo} className="room:h-6 room:w-6" />
        <div id="card-text" className="ml-6 room:ml-2 room:text-xs">
          {scenario.scoringKind !== 'DISABLED' && <Stars value={scenario.highscore || 0} />}

          <div className="text-xl room:text-xs font-bold text-white">{scenario.name}</div>
        </div>
        {scenario.testing && (
          <div className="absolute right-6 top-6 rounded-3xl bg-warp-yellow px-4 py-2 text-sm font-bold">
            Test
          </div>
        )}
      </div>
    </Card>
  )
}

ScenarioCard.propTypes = {
  scenario: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ScenarioCard
