import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const Dropdown = ({ isVisible, toggleVisible, arrow, arrowPosition, children, position, sub }) => {
  const ref = useRef()

  const handleClick = () => {
    toggleVisible(false)
  }

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mouseup', handleClick)
    }
    return () => document.removeEventListener('mouseup', handleClick)
  }, [isVisible])

  const getArrow = () => {
    let pos = {}
    switch (arrowPosition) {
      case 'topright':
        if (position === 'left') pos = { right: '-4px', top: '8px' }
        else pos = { top: '-4px', right: '8px' }
        break
      case 'topleft':
        pos = { top: 0, left: 0 }
        break
      default:
        return null
    }
    return (
      <div
        id="arrow"
        style={pos}
        className="absolute -z-20 block h-8 w-8 rotate-45 rounded-[3px] ring ring-[#ddd]
        after:absolute
        after:z-10
        after:inline-block
        after:h-8
        after:w-8
        after:rounded-[3px]
        after:bg-white
        after:content-['']
        "
      />
    )
  }
  const getPosClasses = () => {
    switch (position) {
      case 'left':
        return 'right-full top-0 mr-1'
      case 'bottom':
      default:
        return 'right-0'
    }
  }
  return (
    <div
      ref={ref}
      className={` ${
        isVisible
          ? 'block z-20 scale-100 opacity-100 ease-out'
          : 'hidden -z-10 scale-75 opacity-0 ease-in'
      } absolute mt-[2px] origin-top-right rounded border bg-white shadow-lg transition-all duration-100 ${getPosClasses()} ${
        sub ? 'w-40' : 'w-72'
      }`}
    >
      <div className="relative">{arrow && getArrow()}</div>
      <div className="rounded bg-white">{children}</div>
    </div>
  )
}

export default Dropdown

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  arrow: PropTypes.bool,
  arrowPosition: PropTypes.string,
  toggleVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(['bottom', 'left', 'right']),
  sub: PropTypes.bool,
}

Dropdown.defaultProps = {
  arrow: false,
  arrowPosition: 'topright',
  position: 'bottom',
  sub: false,
}
