import React, { useContext } from 'react'
import { v4 as uuidV4 } from 'uuid'
import PropTypes from 'prop-types'
import { SceneContext } from '../../../context/sceneContext'
import { ScenarioContext } from '../../../context/scenarioContext'
import TextBubble from './partials/TextBubble'
import { getRotation } from '../helpers/controls'

// Constants to match size of hotspots in the mobile app
const HOTSPOT_SIZES = {
  SMALL: 0.23,
  MEDIUM: 0.43,
  LARGE: 0.63,
}

const Hotspot = ({ element }) => {
  const { colors } = useContext(ScenarioContext)
  const { scene, currentSceneSettings, dispatchAttempt, handleSceneChange, handleEnding } =
    useContext(SceneContext)

  const getHotspot = () => {
    const size = HOTSPOT_SIZES[element.hotspotSize]
    const opacity = () => {
      if (!scene.hotspotHintEnabled || currentSceneSettings.showHint)
        return { ring: 1, circle: 0.6 }
      return { ring: 0, circle: 0 }
    }
    const rotation = getRotation({ x: element.anchorX, y: element.anchorY })

    let labelWidth = 1.8
    if (element.labelSize === 'WIDE') labelWidth *= 2

    return (
      <a-entity
        rotation={`${rotation} 0`}
        hideable-element={`hidden: ${
          !currentSceneSettings.showQuestion ||
          (scene.hotspotHintEnabled && !currentSceneSettings.showHint)
        }`}
      >
        <a-entity position="0 0 -7">
          {/* Hotspot label */}
          {!scene.hideHotspots && (
            <a-entity key={uuidV4()} position={`0 ${size + 0.1} 0`}>
              <TextBubble
                textValue={element.label || 'Hotspot'}
                font={element.labelFont}
                id={`H${element.id}`}
                type="hotspot"
                width={labelWidth}
              />
            </a-entity>
          )}

          {/* Hotspot Ring */}
          <a-entity
            key={uuidV4()}
            geometry={`primitive: ring; radiusInner: ${size - 0.03};radiusOuter:${size};`}
            material={`color: ${colors.primaryColor}; shader:flat; opacity: ${opacity().ring}`}
          />
          {/* Hotspot Circle */}
          <a-circle
            key={uuidV4()}
            data-button
            onClick={() => {
              dispatchAttempt({
                type: 'CHOICE',
                value: { sceneId: scene.id, answerId: element.id },
                uuid: uuidV4(),
              })
              if (element.linkToEnding) return handleEnding()
              return handleSceneChange(element.linkToId)
            }}
            opacity={opacity().circle}
            radius={size}
            material={`color: ${colors.primaryColor}; shader:flat;`}
          />
        </a-entity>
      </a-entity>
    )
  }
  return <a-entity id="hotspot">{getHotspot()}</a-entity>
}
export default Hotspot

Hotspot.propTypes = {
  element: PropTypes.shape.isRequired,
}
