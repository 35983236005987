import React from 'react'
import PropTypes from 'prop-types'

const RoomLayout = ({ children }) => {
  return (
    <div className="room:w-[6000px] room:grid room:grid-cols-6 mx-auto">
      <div className="room:col-start-2 room:flex items-center justify-center room:h-[100vh] room:relative">
        {children}
      </div>
      <div className="hidden room:block bg-white col-start-5 col-span-2" />
    </div>
  )
}

RoomLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
}

export default RoomLayout
