import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  from,
  gql,
} from '@apollo/client'
import { ErrorBoundary } from '@appsignal/react'
import React, { useContext } from 'react'
import { TokenRefreshLink } from 'apollo-link-token-refresh'

import Router from './routes/Router'
import { UserProvider } from './context/userContext'
import { AuthContext } from './context/authContext'
import appsignal from './helpers/appsignal'
import ErrorPage from './pages/ErrorPage'
import RoomLayout from './layouts/RoomLayout'

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_URL}` })

const App = () => {
  const {
    verifyAccessToken,
    hasValidRefreshToken,
    getDevice,
    setTokens,
    sessionType: [sessionType],
  } = useContext(AuthContext)

  const GET_REFRESH_TOKEN = gql`
    mutation createToken($grant: TokenGrant!, $device: DeviceInput!) {
      createToken(grant: $grant, device: $device) {
        accessToken
        refreshToken
      }
    }
  `

  const refreshAuthLink = new ApolloLink((operation, forward) => {
    const refreshToken =
      sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        authorization: refreshToken ? `Bearer ${refreshToken}` : '',
      },
    }))

    return forward(operation)
  })
  const tokenClient = new ApolloClient({
    link: from([refreshAuthLink, httpLink]),
    cache: new InMemoryCache(),
  })

  const refreshLink = new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: async () => {
      verifyAccessToken()
    },
    fetchAccessToken: async () => {
      if (!verifyAccessToken() && hasValidRefreshToken()) {
        await tokenClient
          .mutate({
            mutation: GET_REFRESH_TOKEN,
            variables: {
              grant: 'REFRESH',
              device: getDevice(),
            },
          })
          .then(({ data }) => {
            const { accessToken, refreshToken } = data.createToken
            setTokens(accessToken, refreshToken, sessionType)
          })
      }
    },
    handleError: () => {
      /* overrule error handling */
    },
  })

  const authLink = new ApolloLink((operation, forward) => {
    const accessToken = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken')
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    }))

    return forward(operation)
  })

  const appsignalBreadcrumb = new ApolloLink((operation, forward) => {
    if (operation.operationName) {
      appsignal.addBreadcrumb({
        category: 'GraphQL',
        action: 'request',
        metadata: {
          operationName: operation.operationName,
          variables: operation.variables,
        },
      })
    }

    return forward(operation)
  })

  const client = new ApolloClient({
    link: from([refreshLink, authLink, appsignalBreadcrumb, httpLink]),
    cache: new InMemoryCache(),
  })

  const fallbackComponent = () => <ErrorPage />

  return (
    <ErrorBoundary instance={appsignal} fallback={fallbackComponent}>
      <ApolloProvider client={client}>
        <UserProvider>
          <RoomLayout>
            <Router />
          </RoomLayout>
        </UserProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default App
