const { AFRAME } = window

AFRAME.registerComponent('hide-once-playing', {
  init() {
    this.video = document.querySelector('#video')
    this.onPlaying = this.onPlaying.bind(this)
    this.onPause = this.onPause.bind(this)
  },
  play() {
    if (this.video) {
      this.video.addEventListener('playing', this.onPlaying, true)
      this.video.addEventListener('pause', this.onPause, true)
    }
  },

  onPlaying() {
    this.el.setAttribute('visible', false)
  },
  onPause() {
    if (this.video.currentTime === 0) {
      this.el.setAttribute('visible', true)
    }
  },
})
