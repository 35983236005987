import React from 'react'
import PropTypes from 'prop-types'
import { getRotation } from '../helpers/controls'
import TextBubble from './partials/TextBubble'

const Tooltip = ({ element }) => {
  const rotation = `${getRotation({ x: element.anchorX ?? 0, y: element.anchorY ?? -10 })} 0`

  let labelWidth = 1.8
  if (element.labelSize === 'WIDE') labelWidth *= 2

  return (
    <a-entity rotation={`${rotation} 0`}>
      <a-entity position="0 0 -7">
        <TextBubble
          id={`L${element.id}`}
          textValue={element.label || 'Tooltip'}
          width={labelWidth}
          font={element.labelFont}
        />
      </a-entity>
    </a-entity>
  )
}
export default Tooltip

Tooltip.propTypes = {
  element: PropTypes.shape.isRequired,
}
