import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidV4 } from 'uuid'
import { getRotation } from '../helpers/controls'
import ButtonEntity from './partials/Button'
import { ScenarioContext } from '../../../context/scenarioContext'
import { SceneContext } from '../../../context/sceneContext'

const Button = ({ element }) => {
  const { colors } = useContext(ScenarioContext)
  const { scene, handleSceneChange, dispatchAttempt, handleEnding } = useContext(SceneContext)

  if (!element) return null
  let labelWidth = 2.6
  if (element.labelSize === 'WIDE') labelWidth *= 2

  const rotation = getRotation({
    x: element.anchorX || 0,
    y: element.anchorY || -10,
  })

  return (
    <a-entity rotation={`${rotation} 0`}>
      <a-entity position="0 0 -7">
        <ButtonEntity
          clickHandler={() => {
            dispatchAttempt({
              type: 'CHOICE',
              value: { sceneId: scene.id, answerId: element.id },
              uuid: uuidV4(),
            })
            if (element.linkToEnding) return handleEnding()
            return handleSceneChange(element.linkToId)
          }}
          textValue={element.label || 'Button'}
          width={labelWidth}
          id="button"
          textColor={colors.text}
          font={element.labelFont}
          buttonColor={colors.primaryColor}
        />
      </a-entity>
    </a-entity>
  )
}
export default Button

Button.propTypes = {
  element: PropTypes.shape.isRequired,
}
