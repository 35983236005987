import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../context/userContext'

const LoginByCodeInUrl = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { verificationCode } = useParams()
  const {
    code: [code, setCode],
    loginFailure: [loginFailure],
    login,
  } = useContext(UserContext)

  useEffect(() => {
    if (code) login(navigate)
  }, [code])

  useEffect(() => {
    setCode(verificationCode)
  }, [verificationCode])

  useEffect(() => {
    if (loginFailure) navigate('/login')
  }, [loginFailure])

  return <div>{t('loading', 'Loading')}...</div>
}

export default LoginByCodeInUrl
