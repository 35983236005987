import { gql, useMutation } from '@apollo/client'
import { useEffect, useContext, useReducer } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { ScenarioContext } from '../../context/scenarioContext'
import { LoadContext } from '../../context/loadContext'
import { AuthContext } from '../../context/authContext'

const RECORD_EVENT = gql`
  mutation recordEvent(
    $kind: EventKind!
    $answerId: ID
    $sceneId: ID
    $uuid: ID!
    $scenarioId: ID!
    $startedAt: String!
    $recordedAt: String!
  ) {
    recordEvent(
      event: {
        uuid: $uuid
        scenarioId: $scenarioId
        startedAt: $startedAt
        recordedAt: $recordedAt
        kind: $kind
        answerId: $answerId
        sceneId: $sceneId
        mode: TWOD
        quality: HIGH
        control: MOUSE
      }
    ) {
      status
    }
  }
`

const UPDATE_SESSION = gql`
  mutation updateSession($event: SessionEvent!) {
    updateSession(event: $event) {
      session {
        id
        state
      }
    }
  }
`

const initialState = {
  startedAt: null,
  attemptId: null,
  scenarioId: null,
  answers: [],
}

const useAttempt = () => {
  const { scenarioDetail } = useContext(ScenarioContext)
  const { loading, dispatch: dispatchLoading } = useContext(LoadContext)
  const {
    sessionType: [sessionType],
  } = useContext(AuthContext)

  const [updateEventMutation] = useMutation(RECORD_EVENT)
  const [updateSessionMutation] = useMutation(UPDATE_SESSION)

  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_STATE':
        return { ...state, ...action.value }
      case 'START':
        if (sessionType !== 'LOGIN') updateSessionMutation({ variables: { event: 'START' } })
        updateEventMutation({
          variables: {
            kind: action.type,
            scenarioId: state.scenarioId,
            uuid: action.uuid,
            startedAt: state.startedAt,
            recordedAt: new Date().toLocaleString('sv', { timeZoneName: 'longOffset' }),
          },
        })
        return state
      case 'ABORT':
        if (sessionType !== 'LOGIN') updateSessionMutation({ variables: { event: 'CLOSE' } })
      /* falls through */
      case 'ENDING':
        updateEventMutation({
          variables: {
            kind: action.type,
            scenarioId: state.scenarioId,
            uuid: action.uuid,
            startedAt: state.startedAt,
            recordedAt: new Date().toLocaleString('sv', { timeZoneName: 'longOffset' }),
          },
        })
        return state
      case 'CHOICE':
      case 'TIMER':
        updateEventMutation({
          variables: {
            answerId: action.value.answerId,
            sceneId: action.value.sceneId,
            kind: action.type,
            scenarioId: state.scenarioId,
            uuid: action.uuid,
            startedAt: state.startedAt,
            recordedAt: new Date().toLocaleString('sv', { timeZoneName: 'longOffset' }),
          },
        })

        return { ...state, answers: [...state.answers, action.value.answerId] }
      default:
        return state
    }
  }
  const [state, dispatch] = useReducer(reducer, { ...initialState })

  const handleStartAttempt = () => {
    dispatch({
      type: 'UPDATE_STATE',
      value: {
        attemptId: uuidV4(),
        startedAt: new Date().toLocaleString('sv', { timeZoneName: 'longOffset' }),
      },
    })
  }

  useEffect(() => {
    dispatchLoading({ type: 'UPDATE_LOADING_STATE', value: { attemptsController: true } })
    if (!state.attemptId) handleStartAttempt()
  }, [])

  useEffect(() => {
    if (loading.isLoading.attemptsController && state?.scenarioId !== null) {
      dispatchLoading({ type: 'UPDATE_LOADING_STATE', value: { attemptsController: false } })
    }
  }, [state, loading])

  useEffect(() => {
    if (scenarioDetail && scenarioDetail.id) {
      dispatch({ type: 'UPDATE_STATE', value: { scenarioId: scenarioDetail.id } })
    }
  }, [scenarioDetail])

  return [state, dispatch]
}

export default useAttempt
