import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Card from '../UI/Card/Card'
import ClientLogo from '../UI/ClientLogo'
import scenarioBg from '../../assets/images/scenario.png'
import { isRoom } from '../../helpers/browser'

// Get image url from env
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const CARD_SPANS = ['lg:col-span-6', 'lg:col-span-6', 'lg:col-span-5', 'lg:col-span-7']

const CARD_CLASSES = [
  'aspect-[470/293]',
  'aspect-[470/293]',
  'aspect-[470/293] lg:h-[299px] xl:h-[379px] 2xl:h-[457px] 3xl:h-[634px] lg:aspect-auto',
  'aspect-[470/293] lg:aspect-auto lg:h-full',
]

const ROOM_COLUMN_WIDTH = 1000
const LIMIT = 2

const CoursesTab = ({ courses, roomSlide }) => {
  const { t } = useTranslation()
  const data = [...courses].reduce((array, course) => {
    array.push({
      id: course.id,
      name: course.name,
      description: course.description,
      image: course.scenarios?.[0]?.imageUrl
        ? `${IMAGE_URL}/${course.scenarios?.[0]?.imageUrl}`
        : scenarioBg,
      logo: `${IMAGE_URL}/${course.client.logoMobileUrl}`,
      scenariosCount: course.scenarios.length,
    })
    return array
  }, [])

  const renderCard = (course) => (
    <>
      <div
        className="absolute inset-0 w-full h-full bg-cover"
        style={{ backgroundImage: `url('${course.image}')` }}
      />
      <div className="absolute inset-0 w-full h-full opacity-50 bg-black" />

      <div className="absolute inset-0 flex flex-col justify-between p-6 lg:p-8">
        <div>
          <div>
            <ClientLogo src={course.logo} className="room:h-12 room:w-12" />
          </div>
          <div className="mt-1 lg:mt-3 xl:mt-6">
            <div
              className={`text-[26px] room:text-base font-bold text-white ${
                !course.scenarios?.[0]?.imageUrl ? 'drop-shadow-md' : ''
              }`}
            >
              {course.name}
            </div>
          </div>
        </div>
        <footer>
          <span className="inline-block rounded-3xl bg-white px-3 py-2 text-sm font-bold lg:px-5 lg:py-3 lg:text-base room:text-xs">
            {t('scenariosTotal', { count: course.scenariosCount })}
          </span>
        </footer>
      </div>
    </>
  )

  if (!isRoom())
    return (
      <div className="relative grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-12">
        {data.map((course, index) => (
          <div className={`col-span-12 ${CARD_SPANS[index % 4]}`} key={`course${course.id}`}>
            <Link to={`/courses/${course.id}`}>
              <Card id={`card-${course.id}`} className={CARD_CLASSES[index % 4]}>
                {renderCard(course)}
              </Card>
            </Link>
          </div>
        ))}
      </div>
    )

  const slidesCount = Math.ceil(data.length / LIMIT)
  return (
    <div className="overflow-hidden w-full">
      <div
        className="relative transition-all duration-300 flex"
        style={{
          left: roomSlide * -ROOM_COLUMN_WIDTH,
          width: `${ROOM_COLUMN_WIDTH * slidesCount}px`,
        }}
      >
        {Array.from({ length: slidesCount })
          .map((value, index) => index)
          .map((slide) => (
            <div
              key={`course-${slide}`}
              className="relative grid gap-6 grid-cols-2 grid-rows-1 items-start"
              style={{
                width: `${ROOM_COLUMN_WIDTH}px`,
              }}
            >
              {data
                .filter((d, index) => Math.floor(index / LIMIT) === slide)
                .map((course) => (
                  <Link to={`/courses/${course.id}`} key={`course${course.id}`}>
                    <Card id={`card-${course.id}`} className="aspect-[470/293]">
                      {renderCard(course)}
                    </Card>
                  </Link>
                ))}
            </div>
          ))}
      </div>
    </div>
  )
}

export default CoursesTab

CoursesTab.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape()),
  roomSlide: PropTypes.number,
}
CoursesTab.defaultProps = {
  courses: [],
  roomSlide: 0,
}
