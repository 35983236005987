import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const fallbackLng = 'en'
export const LANGS = [
  { key: 'en', value: 'English' },
  { key: 'nl', value: 'Nederlands' },
  { key: 'de', value: 'Deutsch' },
  { key: 'es', value: 'Español' },
]
const defaultNS = 'main'

const browserLang = navigator.language.split('-')[0] // en, nl, etc
let defaultLanguage = localStorage.getItem('lang') || browserLang
defaultLanguage = (LANGS.find((l) => l.key === defaultLanguage) && defaultLanguage) || fallbackLng

export const changeLanguage = async (lang) => {
  if (!i18n.hasResourceBundle(lang, defaultNS)) {
    await fetch(`/locales/${lang}.json`)
      .then((r) => r.json())
      .then(async (data) => {
        i18n.addResources(lang, defaultNS, data, false, true)
      })
  }

  localStorage.setItem('lang', lang)
  document.documentElement.lang = lang
  i18n.changeLanguage(lang)
}

i18n.use(initReactI18next).init(
  {
    debug: false,
    fallbackLng,
    lng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    defaultNS,
  },
  () => changeLanguage(defaultLanguage)
)
export default i18n
