import React from 'react'
import { FaSlidersH, FaSort } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Filter from '../UI/Menu/Filter'

const DashboardFilters = ({ clients, selectedFilters, setSelectedFilters }) => {
  const { t } = useTranslation()

  const sortList = [
    { value: 'createdAt', render: t('last_added', 'Last added') },
    { value: 'name', render: t('atoz', 'A-Z') },
  ]
  const showList = [
    { value: '', render: t('all', 'All') },
    { value: 'lastAttemptAt', render: t('never_played', 'Never played') },
  ]

  return (
    <div className="flex xs:hidden gap-x-3 flex-wrap">
      <Filter
        text={t('sort_by', 'Sort by')}
        icon={<FaSort />}
        filterHandler={(x) => {
          setSelectedFilters({ ...selectedFilters, sortBy: x })
        }}
        selected={selectedFilters.sortBy}
        filterOptions={sortList}
      />
      <Filter
        text={t('show', 'Show')}
        icon={<FaSlidersH />}
        filterHandler={(x) => {
          setSelectedFilters({ ...selectedFilters, show: x })
        }}
        selected={selectedFilters.show}
        filterOptions={showList}
      />
      <Filter
        text={t('organization', 'Organization')}
        header={t('select_organization', 'Select organization')}
        icon={<FaSlidersH />}
        filterHandler={(x) => {
          setSelectedFilters({ ...selectedFilters, client: x })
        }}
        selected={selectedFilters.client}
        filterOptions={[
          { value: '', render: t('all', 'All') },
          ...clients.map((c) => ({ value: c.id, render: c.appTitle })),
        ]}
      />
    </div>
  )
}

DashboardFilters.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  selectedFilters: PropTypes.shape().isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
}

export default DashboardFilters
