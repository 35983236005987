import React from 'react'
import PropTypes from 'prop-types'
import { FaStar, FaStarHalfAlt } from 'react-icons/fa'

const Stars = ({ value, color }) => {
  const full = Math.floor(value)
  const half = value % 1
  const stars = []
  let counter = 0
  while (stars.length < full) {
    stars.push(<FaStar key={`star-${counter}`} color={color} />)
    counter += 1
  }
  if (half) stars.push(<FaStarHalfAlt key="half-star" color={color} />)
  while (stars.length < 5) {
    stars.push(<FaStar key={`empty-star-${counter}`} className="text-gray-200 text-opacity-60" />)
    counter += 1
  }
  return (
    <div id="stars" className="flex text-white">
      {stars}
    </div>
  )
}
export default Stars

Stars.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
}

Stars.defaultProps = {
  color: 'text-white',
}
