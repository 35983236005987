const roundedRect = (ctx, x, y, width, height, radius) => {
  ctx.moveTo(x, y + radius)
  ctx.lineTo(x, y + height - radius)
  ctx.quadraticCurveTo(x, y + height, x + radius, y + height)
  ctx.lineTo(x + width - radius, y + height)
  ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius)
  ctx.lineTo(x + width, y + radius)
  ctx.quadraticCurveTo(x + width, y, x + width - radius, y)
  ctx.lineTo(x + radius, y)
  ctx.quadraticCurveTo(x, y, x, y + radius)
}

const arrowBubble = (ctx, x, y, width, height, radius) => {
  const arrowSize = width * (width > 3.5 ? 0.025 : 0.05)
  const arrowRadius = radius * (width > 3.5 ? 0.25 : 0.5)
  ctx.moveTo(x, y + radius + arrowSize)
  ctx.lineTo(x, y + height - radius)
  ctx.quadraticCurveTo(x, y + height, x + radius, y + height)
  ctx.lineTo(x + width - radius, y + height)
  ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius)
  ctx.lineTo(x + width, y + radius + arrowSize)
  ctx.quadraticCurveTo(x + width, y + arrowSize, x + width - radius, y + arrowSize)
  // :::ARROW:::
  ctx.lineTo(x + width / 2 + arrowSize, y + arrowSize)
  ctx.lineTo(x + width / 2 + arrowRadius, y + arrowRadius)
  ctx.quadraticCurveTo(x + width / 2, y, x + width / 2 - arrowRadius, y + arrowRadius)
  ctx.lineTo(x + width / 2 - arrowRadius, y + arrowRadius)
  ctx.lineTo(x + width / 2 - arrowSize, y + arrowSize)
  // :::END ARROW:::
  ctx.lineTo(x + radius, y + arrowSize)
  ctx.quadraticCurveTo(x, y + arrowSize, x, y + arrowSize + radius)
}

export { roundedRect, arrowBubble }
