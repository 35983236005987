import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ScenarioContext } from '../../../context/scenarioContext'
import { isMobile } from '../../../helpers/browser'

const ScenarioPlayDevicesTab = ({
  children,
  onClick,
  name,
  value,
  primary,
  selected,
  className,
}) => {
  const { colors, scenarioDetail } = useContext(ScenarioContext)

  const getStyles = () =>
    primary && scenarioDetail ? { backgroundColor: colors.primaryColor, color: colors.text } : {}

  return (
    <button
      type="button"
      onClick={onClick}
      className={`relative mt-3 h-[62px] w-full overflow-hidden rounded bg-white/10 hover:bg-white/20 ${className}`}
    >
      {name && (
        <input
          type="radio"
          value={value}
          name={name}
          className="peer absolute inset-0 h-full w-full cursor-pointer opacity-0"
          checked={selected}
          onChange={() => {}}
        />
      )}
      <div
        className={`flex h-full w-full items-center justify-center text-sm font-bold transition-all 
          ${!isMobile() && 'peer-checked:!bg-white peer-checked:!text-black'}`}
        style={getStyles()}
      >
        {children}
      </div>
    </button>
  )
}
export default ScenarioPlayDevicesTab

ScenarioPlayDevicesTab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  primary: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
}

ScenarioPlayDevicesTab.defaultProps = {
  primary: false,
  selected: false,
  name: '',
  className: '',
  value: '',
  onClick: () => {},
}
