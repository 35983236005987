import React from 'react'
import PropTypes from 'prop-types'

const Card = ({ id, onClick, backgroundImage, children, className }) => (
  <div
    role="none"
    id={id}
    key={id}
    style={{
      backgroundImage: `url("${backgroundImage}")`,
      backgroundSize: 'cover',
    }}
    className={`relative block aspect-[2/1] w-full rounded bg-white shadow-xl transition-all duration-150 md:hover:scale-105 overflow-hidden ${className}`}
    onClick={onClick}
  >
    <div className="absolute flex h-full w-full items-end rounded bg-gradient-to-t from-black via-transparent to-transparent p-6">
      <div id="card-content" className="flex items-center">
        {children}
      </div>
    </div>
  </div>
)

export default Card

Card.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
}

Card.defaultProps = {
  backgroundImage: null,
  onClick: null,
  className: null,
}
