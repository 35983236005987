const clamp = (number, min, max) => {
  if (number < min) return min
  if (number > max) return max
  return number
}
const openFullscreen = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen()
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen()
  }
}

const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  }
}

export { clamp, openFullscreen, closeFullscreen }
