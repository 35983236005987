import { useEffect } from 'react'

const useBackButtonBlocker = (when, onConfirm = () => {}) => {
  const pageUrl = `${window.location?.pathname}${window.location?.hash}`

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    if (when || window.confirm('Changes you made may not be saved.')) {
      onConfirm()
    } else {
      window.history.pushState(null, null, pageUrl)
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent)

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [])

  useEffect(() => {
    if (when) {
      window.history.pushState(null, null, pageUrl)
    }
  }, [when])
}

export default useBackButtonBlocker
