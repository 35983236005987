import React, { useContext } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AuthContext } from '../context/authContext'
import { LoadProvider } from '../context/loadContext'
import { ScenarioProvider } from '../context/scenarioContext'
import { SceneProvider } from '../context/sceneContext'
import LoginLayout from '../layouts/LoginLayout'
import MainLayout from '../layouts/MainLayout'
import ErrorPage from '../pages/ErrorPage'
import Login from '../pages/Login'
import LoginByCodeInUrl from '../pages/LoginByCodeInUrl'
import Player from '../pages/Player'
import Scenario from '../pages/Scenario'
import { UserContext } from '../context/userContext'
import Course from '../pages/Course'
import Dashboard from '../pages/Dashboard'
import DashboardLayout from '../layouts/DashboardLayout'

const Router = () => {
  const {
    isAuthenticated: [isAuthenticated],
    hasValidRefreshToken,
  } = useContext(AuthContext)

  const {
    user: [user],
    lmsScenarioId,
  } = useContext(UserContext)

  const getRoutes = () => {
    if ((!isAuthenticated && !hasValidRefreshToken()) || (user.id && !user.hasAcceptedTermsOfUse)) {
      return (
        <>
          <Route path="/p/:verificationCode" element={<LoginByCodeInUrl />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </>
      )
    }

    const getScenarioRoutes = () =>
      ['scenarios', 'tests'].map((key) => (
        <Route
          key={key}
          path={`/${key}/*`}
          element={
            <ScenarioProvider>
              <Routes>
                <Route
                  path=":scenarioId/play"
                  element={
                    <LoadProvider>
                      <SceneProvider>
                        <Player />
                      </SceneProvider>
                    </LoadProvider>
                  }
                />
                <Route
                  path=":scenarioId"
                  element={
                    <div className="text-white">
                      <MainLayout backgroundColor="bg-black">
                        <div className="container mx-auto">
                          <Scenario />
                        </div>
                      </MainLayout>
                    </div>
                  }
                />

                <Route element={<DashboardLayout />}>
                  <Route path=":scenarioId/modal" element={<Dashboard />} />
                </Route>
              </Routes>
            </ScenarioProvider>
          }
        />
      ))

    const getErrorRoute = () => <Route path="*" element={<ErrorPage />} />

    const getMainRoutes = () => {
      if (!lmsScenarioId)
        return (
          <Route element={<DashboardLayout />}>
            <Route path="courses/:courseId" element={<Course />} />
            <Route path="courses/:courseId/:scenarioId/modal" element={<Course />} />
            <Route path="/courses" element={<Dashboard />} />
            <Route path="/scenarios" element={<Dashboard />} />
            <Route path="/tests" element={<Dashboard />} />
            <Route path="/" element={<Dashboard />} />
          </Route>
        )
      return <Route path="/" element={<Navigate replace to={`scenarios/${lmsScenarioId}`} />} />
    }

    return (
      <>
        <Route path="/p/:verificationCode" element={<Navigate replace to="/" />} />
        {getScenarioRoutes()}
        {getMainRoutes()}
        {getErrorRoute()}
      </>
    )
  }
  return (
    <HashRouter>
      <Routes>
        <Route
          path="login"
          element={
            isAuthenticated && user.hasAcceptedTermsOfUse ? (
              <Navigate replace to="/" />
            ) : (
              <LoginLayout>
                <Login />
              </LoginLayout>
            )
          }
        />
        {getRoutes()}
      </Routes>
    </HashRouter>
  )
}
export default Router
