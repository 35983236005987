import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const InputCode = ({ setCode, error }) => {
  const [code, setLocalCode] = useState(['', '', '', '', '', ''])

  const focusNext = (nextId) => {
    if (nextId !== code.length && nextId >= 0) {
      document.getElementById(`input-${nextId}`).focus()
    }
  }
  const handlePaste = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const clip = e.clipboardData.getData('text').trim()
    const validClip = clip.length === 6 && [...clip].every((n) => /^\d$/.test(n))
    if (validClip) {
      setLocalCode(clip)
      setCode(clip)
      focusNext(code.length - 1)
    }
  }

  useEffect(() => {
    window.addEventListener('paste', handlePaste, true)
    return () => window.removeEventListener('paste', handlePaste, true)
  }, [])

  const handleKeys = (e, index) => {
    const updateCode = (val) => {
      const n = [...code]
      n[index] = val
      setLocalCode(n)
      setCode(n.join(''))
    }
    switch (e.key) {
      case 'ArrowLeft':
        return focusNext(index - 1)
      case 'ArrowRight':
        return focusNext(index + 1)
      case 'Backspace':
        updateCode('')
        return focusNext(index - 1)
      default:
        if (/^\d$/.test(e.key)) {
          updateCode(e.key)
          return focusNext(index + 1)
        }
        return null
    }
  }

  const showError = () => <div className="mt-2 text-sm text-warp-red">{error.message}</div>

  const generateCode = () => {
    const fields = []
    for (let i = 0; i < code.length; i += 1) {
      fields.push(
        <input
          key={`input-${i}`}
          id={`input-${i}`}
          className={`${error.show ? 'border-warp-red' : ''} ${
            i === code.length - 1 ? 'mr-0' : 'mr-2'
          } inline-block lg:h-[84px] lg:w-[58px] h-[64px] w-[44px] appearance-none rounded border border-hex-ddd bg-hex-eee text-center lg:text-3xl text-lg font-bold text-gray-500 placeholder-hex-ddd transition-all duration-200 focus:bg-white focus:shadow-xl focus:ring px-0`}
          type="number"
          placeholder="0"
          maxLength="1"
          required
          value={code[i]}
          readOnly={false}
          onPaste={(e) => handlePaste(e)}
          onChange={() => {
            /**
             * this function is intentionally left blank
             */
          }}
          onKeyDown={(e) => {
            // Prevent entering of invalid values
            const allowedKeys = ['Tab', 'Backspace', 'ArrowRight', 'ArrowLeft']
            const isNumber = /^\d$/.test(e.key)
            const isPasting = e.key === 'v' && e.ctrlKey
            if (!allowedKeys.includes(e.key) && !isPasting && !isNumber) e.preventDefault()
          }}
          onKeyUp={(e) => {
            handleKeys(e, i)
          }}
          min={0}
          max={9}
        />
      )
    }
    fields.splice(
      3,
      0,
      <span key="separator" className="mr-3 ml-1 text-3xl font-bold">
        -
      </span>
    )
    return (
      <div className="lg:mx-10 mx-2 max-w-full lg:origin-[25%_25%] lg:scale-75 whitespace-nowrap scale-100">
        {fields}
        {error.show && showError()}
      </div>
    )
  }
  return generateCode()
}
export default InputCode

InputCode.propTypes = {
  setCode: PropTypes.func.isRequired,
}
