import { gql, useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'

const LICENSE_CHECK = gql`
  mutation licenseCheck($scenarioId: ID!) {
    licenseCheck(scenarioId: $scenarioId) {
      result
    }
  }
`

const GET_SCENARIO_DETAILS = gql`
  query getScenarios($id: ID!) {
    scenarios(id: $id) {
      id
      license {
        status
      }
    }
  }
`

const useScenarioLicenseCheck = ({ scenarioId }) => {
  const [licenseResult, setLicenseResult] = useState()
  const [licenseStatus, setLicenseStatus] = useState()

  const [licenseCheck] = useMutation(LICENSE_CHECK, {
    variables: {
      scenarioId,
    },
    onCompleted: ({ licenseCheck: { result } }) => {
      setLicenseResult(result)
    },
  })

  useQuery(GET_SCENARIO_DETAILS, {
    skip: !scenarioId,
    variables: { id: scenarioId },
    onError: (error) => {
      throw new Error(error.message)
    },
    onCompleted: (data) => {
      const { status } = data.scenarios[0].license
      if (status === 'MISSING') licenseCheck()
      setLicenseStatus(status)
    },
  })

  return { licenseResult, licenseStatus }
}

export default useScenarioLicenseCheck
