import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from '../../Modal'
import ScenarioPlayDevices from './ScenarioPlayDevices'

const ScenarioPlayDevicesModal = ({ show, onClose }) => {
  const { t } = useTranslation()
  return (
    <Modal show={show} maxWidth="md" onClose={onClose}>
      <div className="-mx-14 bg-black px-10 text-white">
        <div className="py-[60px] px-[20px] ">
          <h2 className="mt-5 mb-10 pr-12 text-2xl font-bold">
            {t('play_scenario_devices_title', 'Play scenarios on a VR headset, phone or tablet')}
          </h2>
          <ScenarioPlayDevices />
        </div>
      </div>
    </Modal>
  )
}

export default ScenarioPlayDevicesModal

ScenarioPlayDevicesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
