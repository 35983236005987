export const sortOnKey = (array, key, isAscending) => {
  if (!array.length) return array

  // If key does not exist on array
  if (!array.some((o) => Object.keys(o).includes(key))) {
    // throw new Error('Key not found')
    return array
  }

  // check if key has a subkey named 'value'
  const hasValueKey = array[0][key] && Object.keys(array[0][key]).includes('value')
  const sorted = array.sort((a, b) => {
    let an
    let bn

    if (hasValueKey) {
      an = a[key].value
      bn = b[key].value
    } else {
      an = a[key]
      bn = b[key]
    }
    if (!an) return -1
    if (!bn) return 1

    return an.toString().localeCompare(bn.toString(), 'en', { numeric: true })
  })
  return isAscending ? sorted.reverse() : sorted
}
