import React, { useContext, useRef, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import InputCode from '../components/UI/Input/InputCode'
import { UserContext } from '../context/userContext'
import { AuthContext } from '../context/authContext'

const SENT_CODE_THROUGH_EMAIL = gql`
  mutation startSession($email: String!, $locale: Locale!) {
    startSession(email: $email, locale: $locale) {
      success
    }
  }
`

const ACCEPT_TERMS = gql`
  mutation acceptTerms {
    acceptTerms(termsOfUseAccepted: true) {
      currentUser {
        hasAcceptedTermsOfUse
      }
    }
  }
`

const LoginForm = () => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const [requestCode, setRequestCode] = useState(false)
  const [signInCodeSent, setSignInCodeSent] = useState(false)
  const [error, setError] = useState({
    show: false,
    message: '',
  })

  const {
    user: [user, setUser],
    code: [, setCode],
    login,
  } = useContext(UserContext)

  const {
    isAuthenticated: [isAuthenticated],
  } = useContext(AuthContext)

  const [sentCodeThroughEmail] = useMutation(SENT_CODE_THROUGH_EMAIL, {
    onCompleted: () => setSignInCodeSent(true),
  })
  const [acceptTermsMutation] = useMutation(ACCEPT_TERMS, {
    onCompleted: ({
      acceptTerms: {
        currentUser: { hasAcceptedTermsOfUse },
      },
    }) => {
      setUser({ ...user, hasAcceptedTermsOfUse })
    },
  })

  const navigate = useNavigate()

  const enterEmailForm = () => {
    const handleSubmit = (e) => {
      e?.preventDefault()
      sentCodeThroughEmail({
        variables: {
          email: inputRef.current.value,
          locale: 'EN',
        },
      })
    }

    if (!signInCodeSent) {
      return (
        <form className="flex w-full flex-col items-center" onSubmit={handleSubmit}>
          <p className="mb-10 text-sm text-warp-gray">
            {t(
              'fill_email_to_receive_code',
              'Fill in your email address to receive a 6-digit code by email'
            )}
            .
          </p>
          <input
            ref={inputRef}
            id="email"
            type="email"
            required
            placeholder="Enter your email address..."
            className="inline-block w-full appearance-none rounded border-hex-ddd bg-hex-eee py-3 px-4 text-warp-gray outline-none transition-all focus:bg-white focus:shadow-md focus:ring"
          />
          <button
            type="submit"
            title="Send me the code"
            className="mt-3 mb-1 w-full rounded bg-warp-red p-3 px-5 font-bold text-white hover:bg-warp-red-active focus:bg-warp-red-active"
          >
            {t('send', 'Send')}
          </button>
        </form>
      )
    }

    return (
      <div className="text-center">
        <h1 className="mb-5 text-sm font-bold">
          {t('perfect', 'Perfect')}!{' '}
          {t('we_sent_code_in_email', 'We have sent you an email with a 6-digit code')}.
          <br />
          {t('code_is_valid_for_mins', {
            mins: 60,
            defaultValue: 'This code is valid for 60 minutes and can only be used once',
          })}
          .
        </h1>
        <p className="my-2 text-sm text-warp-gray">
          {t('did_not_receive_code', "Didn't receive a code?")}{' '}
          <Trans
            i18nKey="check_spam_try_again"
            defaults="Check your spam folder or <button>try again</button>"
            components={{
              button: (
                <button
                  type="button"
                  className="text-warp-red underline"
                  onClick={() => setSignInCodeSent(false)}
                  aria-label="try again"
                />
              ),
            }}
          />
          .{' '}
          {t(
            'you_receive_email_when_register_trainer',
            'You only receive an email when you are registered as a trainee in Warp Studio.'
          )}
        </p>
      </div>
    )
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    setError({
      show: false,
      message: '',
    })
    login(navigate).catch(() => {
      setError({
        show: true,
        message: t(
          'wrong_code_error',
          'Oh no, it seems your code was incorrect. Please try again.'
        ),
      })
    })
  }

  return (
    <>
      {!isAuthenticated && (
        <>
          <h1 className="mt-3 mb-8 text-2xl font-bold md:mt-16 md:mb-12 md:text-3xl text-center">
            {t('welcome', 'Welcome')}. {t('sign_in_play', 'Sign in and play')}
          </h1>
          <div className="mb-10 flex w-full cursor-pointer rounded border text-center text-base font-bold  room:hidden">
            <button
              type="button"
              className={`w-full py-3.5 transition-all ${
                !requestCode ? 'bg-hex-ddd' : 'text-warp-gray'
              }`}
              onClick={() => setRequestCode(false)}
            >
              {t('I_have_code', 'I have a code')}
            </button>
            <button
              type="button"
              className={`w-full py-3.5 transition-all ${
                requestCode ? 'bg-hex-ddd' : 'text-warp-gray'
              }`}
              onClick={() => setRequestCode(true)}
            >
              {t('I_dont_have_code', "I don't have a code")}
            </button>
          </div>

          {requestCode && enterEmailForm()}

          {!requestCode && (
            <form className="flex max-w-full flex-col items-center" onSubmit={handleLogin}>
              <InputCode setCode={(v) => setCode(v)} error={error} />

              <button
                type="submit"
                className="mt-10 w-full rounded bg-warp-red py-3 font-bold text-white transition-all hover:bg-warp-red-active focus:bg-warp-red-active focus:ring"
              >
                {t('sign_in', 'Sign in')}
              </button>
            </form>
          )}
        </>
      )}

      {isAuthenticated && !user.hasAcceptedTermsOfUse && (
        <>
          <h1 className="mt-3 mb-8 text-2xl font-bold md:mt-16 md:mb-12 md:text-3xl text-center">
            {t('accept_terms_title', 'Welcome to Warp VR')}
          </h1>
          <p className="text-center text-sm text-gray-400">
            <Trans
              i18nKey="accept_terms_message"
              defaults="Simply select a scenario, download and start playing. Good luck! Since this is your first sign in, you need to agree to our <a href='https://www.warpvr.com/terms'>Terms of Use</a>."
              components={{
                a: (
                  <a className="text-warp-red underline" target="_blank" rel="noreferrer">
                    Terms
                  </a>
                ),
              }}
            />
          </p>

          <button
            type="button"
            className="mt-10 w-full rounded bg-warp-red py-3.5 text-center font-bold text-white transition-all"
            onClick={() => acceptTermsMutation()}
          >
            {t('accept_terms_button')}
          </button>
        </>
      )}
    </>
  )
}

export default LoginForm
