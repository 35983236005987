import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { FaTimes } from 'react-icons/fa'
import Scenario from '../../pages/Scenario'
import Modal from '../Modal'
import { ScenarioContext } from '../../context/scenarioContext'

const ScenarioModal = ({ showModal, scenario, onClose }) => {
  const { resetScenarioDetail } = useContext(ScenarioContext)
  const modalRef = useRef()

  const closeModalHandler = () => {
    if (document.querySelector('#about-org-modal')) return false
    resetScenarioDetail()
    // Remove fade in animation:
    modalRef.current?.classList.remove('animate-modal-fade-in')
    modalRef.current?.classList.add('animate-modal-fade-out')
    setTimeout(() => {
      onClose()
    }, 250)
    return true
  }

  return (
    <Modal ref={modalRef} show={showModal} onClose={closeModalHandler} closeButton={false}>
      <div className="-mx-14 bg-black text-white">
        <div className="absolute top-0 right-0 z-10 p-5 text-lg">
          <button type="button" label="close" onClick={closeModalHandler}>
            <div className="rounded-50 bg-gray-800 bg-opacity-40 p-2 text-white transition-all hover:bg-gray-900 hover:bg-opacity-60">
              <FaTimes title="close" />
            </div>
          </button>
        </div>
        <div className="relative">
          <Scenario id={scenario.id} course={scenario.course} />
        </div>
      </div>
    </Modal>
  )
}

export default ScenarioModal

ScenarioModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  scenario: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
}
