const { AFRAME } = window

AFRAME.registerComponent('mpc', {
  schema: {
    elementSize: { default: 'NORMAL' },
    answerElementSize: { default: 'NORMAL' },
  },
  loadList() {
    let x = this.data.elementSize === 'WIDE' ? 3.405 : 2.45
    const y = this.data.answerElementSize === 'WIDE' ? 0.21 : 0.19
    if (this.data.answerElementSize === 'WIDE') x += 1.4
    if (this.data.elementSize === 'WIDE') x += 0.05
    this.el.setAttribute('position', `${x} ${y} 0`)

    const buttons = Array.from(this.el.children).reverse()
    buttons.forEach((button) => {
      const height = Number(button.getAttribute('height'))
      const positionY = this.currentHeight + height / 2 + this.spacing
      button.setAttribute('position', `0 ${positionY} 0`)
      this.currentHeight += height + this.spacing
    })
  },
  init() {
    this.attachedChildren = 0
    this.spacing = 0.075
    this.currentHeight = 0
    this.el.setAttribute('rotation', '0 -24 0')

    this.el.addEventListener('button-loaded', () => {
      this.attachedChildren += 1
      if (this.attachedChildren === this.el.children.length) {
        this.loadList()
      }
    })
  },
})
