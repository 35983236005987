import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const FeedbackButton = ({ email }) => {
  const { t } = useTranslation()

  return (
    <a
      href={`https://notionforms.io/forms/web-player-feedback-form?c6c59fe2-9efe-4fca-b0a2-fa1591c25430=${encodeURIComponent(
        email
      )}`}
      target="_blank"
      className="grid h-12 place-content-center rounded-3xl bg-black px-5 text-sm font-bold text-warp-yellow opacity-70 hover:cursor-pointer"
      rel="noreferrer"
    >
      {t('feedback', 'Feedback')}
    </a>
  )
}
export default FeedbackButton

FeedbackButton.propTypes = {
  email: PropTypes.string.isRequired,
}
